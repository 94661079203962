import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row, Spinner } from "reactstrap";
import { BlockTitle, Icon } from "../../components/Component";
import Cookies from "js-cookie";
import Tiktok from "../../images/tiktok.png";
import footerData from "../../jsonfiles/Footer.json";
import { getCompanyFeaturePerformance } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { doc, onSnapshot, increment, updateDoc, getDoc, setDoc, arrayUnion } from "firebase/firestore";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import { map } from "leaflet";
import { getsitesetting } from "../../http/get/getApi";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { db } from "../../layout/footer/Firebase";
import SpeedoMeter from "../../layout/footer/SpeedoMeter";
import { createcontext } from "../../layout/Index";
import constructionfooterlogo from "../images/constructionlogo.png"


const Construction_Footer = () => {
  const {
    data: phonedata,
    isLoading: phonedataLoading,
    isError: phonedataError,
  } = useQuery({
    queryKey: ["get-mail-list"],
    queryFn: () => getsitesetting(),
    staleTime: Infinity,
  });

  const {
    data: maildata,
    isLoading: mailListLoading,
    isError: mailListError,
  } = useQuery({
    queryKey: ["get-mail-list"],
    queryFn: () => getsitesetting(),
    staleTime: Infinity,
  });

  const id = useParams();
  const [count, setCount] = useState(0);
  const [ipCheck, setIPCheck] = useState("");
  const [countdata, setCountData] = useState(10);

  // Function to get IP address
  const ipcheck = async () => {
    try {
      const res = await fetch("https://api.ipify.org?format=json");
      const response = await res.json();
      setIPCheck(response?.ip || "");
    } catch (err) {
      console.log("Error:" + err);
    }
  };
  useEffect(() => {
    ipcheck();
  }, []);

  useEffect(() => {
    const countDocRef = doc(db, "visitorCounts", "countDoc");

    const initializeCount = async () => {
      if (!ipCheck) return;

      const docSnap = await getDoc(countDocRef);

      if (!docSnap.exists()) {
        await setDoc(countDocRef, { count: 1, ips: [ipCheck] });
        setCount(1);
      } else {
        const data = docSnap.data();
        const existingIps = data.ips || [];

        if (existingIps.includes(ipCheck)) {
          setCount(data.count);
        } else {
          await updateDoc(countDocRef, {
            count: increment(1),
            ips: arrayUnion(ipCheck),
          });
        }
      }
    };

    // Listen for real-time updates
    const unsubscribe = onSnapshot(countDocRef, (doc) => {
      if (doc.exists()) {
        setCount(doc.data().count);
      }
    });

    initializeCount();

    return () => unsubscribe();
  }, [ipCheck]); // Rerun this effect only if ipCheck changes

  const {
    data: featuredDJs,
    isLoading: featuredDJsDjsLoading,
    isError: featuredDJsDjsError,
  } = useQuery({
    queryKey: ["get-featuredDJsDjs"],
    queryFn: () => getCompanyFeaturePerformance({ type: 5 }),
    staleTime: Infinity,
  });

  const [selectedFooter, setSelectedFooter] = useState(null);
  useEffect(() => {
    const activeEnv = getActiveEnvironmentVariable();
    setSelectedFooter(footerData?.[activeEnv]);
  }, []);

  const handleCookieSet = (val) => {
    const now = new Date();
    const expires = new Date(now.getTime() + 5 * 1000);
    Cookies.set("serviceId", val, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  };

  //const { heroSection } = useContext(createcontext);
  const { heroSection } = useContext(createcontext);

  const getIconColor = (platform) => {
    switch (platform) {
      case "Facebook":
        return "#316FF6";
      case "Instagram":
        return "#E1306C";
      case "LinkedIn":
        return "#0072b1";
      case "Pinterest":
        return "#E60023";
      default:
        return "#000";
    }
  };

  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  return (
    <div className="nk-footer p-0">
        <>
          <div className="container-fluid">
            <Row className="justify-around m-3">
              <Col lg={3} className="justify-center">
                <div className="d-flex flex-column justify-center">
                  <div className="justify-center" style={{ width: "150px" }}>
                    <Link to="/">
                      <img src={constructionfooterlogo} alt="Logo" style={{ width: "auto", height: "80px" }} />
                    </Link>
                  </div>
                  <div className="d-flex justify-center flex-column">
                    <BlockTitle className="mx-1 mb-0">
                      <span style={{ fontSize: "18px" }} className="mx-1 fw-bold">
                        {selectedFooter?.titlefollow}
                      </span>
                    </BlockTitle>
                    <div className="d-flex my-1">
                      {selectedFooter?.socialMedia?.map((icon) => (
                        <span className="mx-1" key={icon.platform}>
                          <Link to={icon.link} target="_blank">
                            <div className="parentUI">
                              <div className="childUI child-1UI">
                                <button
                                  className="buttonUI btn-2UI align-center"
                                  style={{ color: getIconColor(icon.platform) }}
                                >
                                  {icon.platform === "TikTok" ? (
                                    <img src={Tiktok} alt={icon.platform} />
                                  ) : (
                                    <Icon name={icon.icon} style={{ color: getIconColor(icon.platform) }} />
                                  )}
                                </button>
                              </div>
                            </div>
                          </Link>
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="row justify-between ">
                  <div className="col-md-3 col-auto p-0">
                    <BlockTitle className="text-primary">
                      <span style={{ fontSize: "18px" }} className="fw-bold">
                        {selectedFooter?.title}
                      </span>
                    </BlockTitle>
                    <ul className="" style={{ fontSize: "14px" }}>
                      {selectedFooter?.links.map((linkItem, index) => (
                        <li key={index}>
                          <Link
                            to={linkItem.path}
                            className={currentUrl === linkItem.path ? "text-primary nav-link" : "infoSection"}
                            // target={linkItem.name === "Blog" ? "_blank" : "_self"}
                          >
                            {linkItem.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-3 col-auto p-0">
                    <BlockTitle className="text-primary">
                      <span style={{ fontSize: "18px" }} className="fw-bold">
                        {selectedFooter?.Accout?.title}
                      </span>
                    </BlockTitle>
                    <ul className="" style={{ fontSize: "14px" }}>
                      {selectedFooter?.Accout?.links.map((linkItem, index) => (
                        <li key={index}>
                          <Link
                            to={process.env.REACT_APP_ACCOUNT_URL + linkItem?.path}
                            className={currentUrl === linkItem.link ? "text-primary" : "infoSection"}
                          >
                            {linkItem.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-4 col-auto p-0">
                    <BlockTitle className="text-primary">
                      <span style={{ fontSize: "18px" }} className="fw-bold">
                        {selectedFooter?.contact?.title}
                      </span>
                    </BlockTitle>
                    <ul className="" style={{ fontSize: "14px" }}>
                      {selectedFooter?.contact?.links.map((linkItem, index) => (
                        <li key={index}>
                          <Link
                            to={linkItem.link}
                            className={currentUrl === linkItem.link ? "text-primary" : "infoSection"}
                          >
                            {linkItem.name}
                          </Link>
                        </li>
                      ))}

                      {phonedataLoading ? (
                        <ShimmerThumbnail height={30} width={130} />
                      ) : (
                        maildata && (
                          <li>
                            <b>Email:</b>
                            <a
                              href={`mailto:${phonedata?.data?.site_settings?.contact?.support_email}`}
                              className="infoSection"
                            >
                              {" "}
                              {phonedata?.data?.site_settings?.contact?.support_email}
                            </a>
                          </li>
                        )
                      )}
                      {phonedata && (
                        <li>
                          <b>Contact: </b>
                          <a
                            href={`tel:${phonedata?.data?.site_settings?.contact?.support_phone}`}
                            className="infoSection"
                          >
                            <b>{phonedata?.data?.site_settings?.contact?.support_phone}</b>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </Col>
              {/* For IPDM shows the 5 top-rated DJ's list. */}
              <Col lg={3} className="justify-center ps-lg-5 px-0">
                <div className="row justify-between w-100 ps-0 ps-lg-5">
                  <div className="col-md-3 col-auto p-0 w-100">
                    <BlockTitle className="text-primary">
                      <span style={{ fontSize: "18px" }} className="fw-bold">
                        {selectedFooter?.highProfileDJs?.title || " "}
                      </span>
                    </BlockTitle>
                    {featuredDJsDjsLoading ? (
                      <div className="mx-1 align-center h-50">
                        <Spinner size="sm" color="light" />
                        <span className="px-1">Loading...</span>
                      </div>
                    ) : featuredDJsDjsError ? (
                      <div className="h-50 align-center w-60"></div>
                    ) : featuredDJs?.data?.recent_plans?.length > 0 ? (
                      featuredDJs?.data?.recent_plans.slice(0, 5).map((item, idx) => (
                        <ul className="" style={{ fontSize: "14px" }} key={idx}>
                          <li>
                            <Link
                              className={
                                "/profiles/specific/" + item.user_id === "/profiles/specific/" + id?.id
                                  ? "text-primary"
                                  : "infoSection"
                              }
                              to={`${process.env.REACT_APP_PUBLIC_URL}profiles/specific/${item.user_id}`}
                            >
                              {item?.user_profile?.company_name}
                            </Link>
                          </li>
                        </ul>
                      ))
                    ) : (
                      (featuredDJs?.data?.Platinum.slice(0, 5).map((item, idx) => (
                        <ul className="" style={{ fontSize: "14px" }} key={idx}>
                          <li>
                            <Link
                              className={
                                "/profiles/specific/" + item.user_id === "/profiles/specific/" + id?.id
                                  ? "text-primary"
                                  : "infoSection"
                              }
                              to={`${process.env.REACT_APP_PUBLIC_URL}profiles/specific/${item.user_id}`}
                            >
                              {item?.user_profile?.company_name}
                            </Link>
                          </li>
                        </ul>
                      )))(<div>{selectedFooter?.highProfileDJs?.title1}</div>)
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          <div className="container-fluid p-2 border-top" style={{ fontSize: "14px" }}>
            <Row className="nk-footer-copyright justify-center">
              <Col md={2} className="text-start">
                {/* <p>Visitors Count: {count}</p> */}
                <div className="d-flex flex-coulmn align-center w-100">
                  <p className="me-1 mb-0">Visitors Count: </p>
                  <span className="">
                    <SpeedoMeter value={count} />
                  </span>
                </div>
              </Col>
              <Col md={10} className="text-center">
                <p className="pe-4">
                  {selectedFooter?.copyright}{" "}
                  <Link to={selectedFooter?.company_name_path} target="_blank">
                    {selectedFooter?.company_name}
                  </Link>
                </p>
              </Col>
            </Row>
          </div>
        
        </>  
     
    </div>
    
  );
};

export default Construction_Footer;
