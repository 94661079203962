import React from "react";
import { useLocation } from "react-router-dom";
import "../css/Blogs.css";
import '../css/App.css';

function ReadMorePage() {
  const location = useLocation();
  const { card } = location.state; // Getting the card data passed from BlogCard.jsx

  // Common 20-line description
  const commonDescription = `
    Welcome to our comprehensive guide on choosing the right property for your needs. 
    Avishkar Infra understands the importance of making informed decisions when it comes to real estate investments. 
    Whether you are looking for your dream home or a commercial space to grow your business, there are several factors to consider. 
    Location is one of the most critical aspects. A prime location not only adds value to your property but also ensures convenience in your daily life.

    Budgeting is another essential step in this journey. Knowing how much you can invest helps narrow down your choices and ensures a smooth purchase process. 
    It’s equally important to evaluate the reputation of the developer or builder. Working with trusted developers like Avishkar Infra ensures quality construction and adherence to promised timelines.

    Legal and financial due diligence is non-negotiable. Ensure that all property documents are verified and that the property is free from any encumbrances. 
    Once you’ve shortlisted a property, take the time to visit it personally. Inspecting the property gives you a real sense of its quality and suitability for your needs.

    Additionally, consider future growth prospects of the location, especially for investments. 
    Areas with upcoming infrastructure developments, such as highways, metro stations, or commercial hubs, are likely to see increased property value. 

    Finally, always consult financial advisors or real estate experts to guide you through the process. Their expertise can help you avoid pitfalls and make confident decisions.

    At Avishkar Infra, we’re committed to helping you navigate this important journey. With a variety of residential and commercial properties, we cater to diverse requirements and budgets. 
    Our team is dedicated to ensuring transparency, trust, and timely delivery in all our projects. Partner with us to make your real estate journey hassle-free and successful.
  `;

  return (
    <section className="read-more-page py-5">
      <div className="row">
        <div className="col-md-8">
          {/* Title */}
          <h1 className="display-4 fw-bold text-tyle mb-4">{card.title}</h1>
          
          {/* Date and Badge */}
          <div className="d-flex align-items-center mb-4">
            <span className="badge bg-style me-2">Blog</span>
            <span className="text-muted">{new Date().toLocaleString()}</span>
          </div>

          {/* Common Description */}
          <p className="">{commonDescription}</p>
        </div>

        <div className="col-md-4 text-center">
          {/* Image with hover effect */}
          <img
            src={card.image}
            alt={card.title}
            className="img-fluid rounded shadow-lg hover-shadow"
          />
        </div>
      </div>
    </section>
  );
}

export default ReadMorePage;
