import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Card, CardImg, CardBody, CardTitle, CardText, Button } from "reactstrap";

const DJCarousel = ({ data, placeholderImg, currency, navigate }) => {
    console.log("data",data);
  return (
    <div 
    className="djslide">
      {/* Fix for black background */}
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        modules={[Navigation]}
        spaceBetween={20}
        slidesPerView={1}
        loop={true} // Enable infinite loop
        navigation
        pagination={{ clickable: true }}
        autoplay={{ delay: 3000 }}
        breakpoints={{
          1440: {
            slidesPerView: 4, // Still desktop but overlaps the range
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3, // Matches 'tablet' in 'responsive'
            spaceBetween: 10,
          },
          600: {
            slidesPerView: 2, // Matches 'mobile' in 'responsive'
            spaceBetween: 10,
          },
          400: {
            slidesPerView: 1, // Matches 'mobile' in 'responsive'
            spaceBetween: 10,
          },
        }}
      >
        {data?.length > 0 &&
          data.slice(0, 5).map((profile) => (
            <SwiperSlide key={profile?.user_id}>
              <div className="shadow-sm h-100 mx-2">
                {/* <Card className="h-75">
                  <CardImg
                    top
                    src={profile?.user_profile?.profile_image_path || placeholderImg}
                    alt={profile?.user_profile?.company_name}
                    className="rounded-top object-fit-contain h-50"
                    style={{ cursor: "pointer" }}
                  />
                  <CardBody className="p-4 d-flex flex-column justify-content-between">
                    <div style={{ cursor: "pointer" }}>
                      <CardTitle tag="h5">{profile?.user_profile?.company_name}</CardTitle>
                      <CardText className="text-muted">{profile?.user_profile?.city || "---"}</CardText>
                      <CardText className="mb-3">
                        Starting from: {currency}{" "}
                        {new Intl.NumberFormat("en-IN", { currency: "INR" }).format(
                          profile?.user_profile?.min_package_price || 0
                        )}{" "}
                        {currency === "$" ? "USD" : "INR"}{" "}
                      </CardText>
                      <CardText>
                        {profile?.user_profile?.uniqueness && profile?.user_profile?.uniqueness.length > 200 ? (
                          <>
                            {profile?.user_profile?.uniqueness.substring(0, 200)}
                            <span
                              style={{ cursor: "pointer" }}
                              className="text-primary fw-bold"
                              onClick={() => navigate(`/profiles/specific/${profile?.user_id}`)}
                            >
                              ...
                            </span>
                          </>
                        ) : (
                          profile?.user_profile?.uniqueness || "----"
                        )}
                      </CardText>
                    </div>
                    <div className="mt-3">
                      <Button
                        color="primary"
                        className="me-2 rounded-pill w-100 d-flex justify-content-center align-items-center"
                        onClick={() => navigate(`/profiles/specific/${profile?.user_id}`)}
                      >
                        View Details
                      </Button>
                    </div>
                  </CardBody>
                </Card> */}
                <Card className="h-100">
                  <CardImg
                    top
                    src={profile?.user_profile?.profile_image_path || placeholderImg}
                    alt={profile?.user_profile?.company_name}
                    className="rounded-top  object-fit-contain h-50"
                    style={{ cursor: "pointer" }}
                  />
                  <CardBody className="p-4 d-flex flex-column justify-content-between">
                    <div style={{ cursor: "pointer" }}>
                      <CardTitle tag="h5">{profile?.user_profile?.company_name}</CardTitle>
                      <CardText className="text-muted">{profile?.user_profile?.city || "---"}</CardText>
                      <CardText className="mb-3">
                        Starting from: {currency}{" "}
                        {new Intl.NumberFormat("en-IN", { currency: "INR" }).format(
                          profile?.user_profile?.min_package_price || 0
                        )}{" "}
                        {currency === "$" ? "USD" : "INR"}{" "}
                      </CardText>
                      <CardText>
                        {profile?.user_profile?.uniqueness && profile?.user_profile?.uniqueness.length > 200 ? (
                          <>
                            {profile?.user_profile?.uniqueness.substring(0, 200)}
                            <span
                              style={{ cursor: "pointer" }}
                              className="text-primary fw-bold"
                              onClick={() => navigate(`/profiles/specific/${profile?.user_id}`)}
                            >
                              ...
                            </span>
                          </>
                        ) : (
                          profile?.user_profile?.uniqueness || "----"
                        )}
                      </CardText>
                    </div>
                    <div className="mt-3">
                      <Button
                        color="primary"
                        className="me-2 rounded-pill w-100 d-flex justify-content-center align-items-center"
                        onClick={() => navigate(`/profiles/specific/${profile?.user_id}`)}
                      >
                        View Details
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default DJCarousel;
