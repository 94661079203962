import React from "react";
import { useNavigate } from "react-router";
import { Icon } from "../../../components/Component";
import { Alert, Button, Col, Row } from "reactstrap";
import { useQuery } from "react-query";
import { getAllServices } from "../../../http/get/getApi";
import placeHolderImg from "../../../images/No Result.png";
import placeHolderImg1 from "../../../images/placeholderimageforallproject.jpg";
import { ShimmerBadge, ShimmerButton, ShimmerText, ShimmerThumbnail, ShimmerTitle } from "react-shimmer-effects";
import about from "../../../jsonfiles/About.json";
import { getActiveEnvironmentVariable } from "../../../utils/Utils";

const ServiceCards = () => {
  const navigate = useNavigate();

  const activeVariable = getActiveEnvironmentVariable();

  const {
    data: serviceList,
    isLoading: serviceListLoading,
    isError: serviceListError,
  } = useQuery({
    queryKey: ["get-service-list"],
    queryFn: () => getAllServices(),
    staleTime: Infinity,
  });

  return (
    <>
      <div class="container my-5">
        <div class="text-center mb-3">
          <div class="fs-4">{about?.[activeVariable]?.section2?.map((item) => item?.title)}</div>
          <p className="mb-0">{about?.[activeVariable]?.section2?.map((item) => item?.text)}</p>
          <div class="fs-2 fw-medium text-primary mt-2">
            {about?.[activeVariable]?.section2?.map((item) => item?.title2)}
          </div>
        </div>
        <div class="row g-4 py-2 overflow-auto mt-1" style={{ height: "600px", scrollbarWidth: "none" }}>
          {serviceListLoading ? (
            <Row>
              <Col lg={4}>
                <ShimmerBadge width={150} />
                <ShimmerText />
                <ShimmerButton size="md" />
              </Col>
              <Col lg={4}>
                <div>
                  <ShimmerBadge width={150} />
                  <ShimmerText />
                  <ShimmerButton size="md" />
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <ShimmerBadge width={150} />
                  <ShimmerText />
                  <ShimmerButton size="md" />
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <ShimmerBadge width={150} />
                  <ShimmerText />
                  <ShimmerButton size="md" />
                </div>
              </Col>
              <Col lg={4}>
                <div>
                  <ShimmerBadge width={150} />
                  <ShimmerText />
                  <ShimmerButton size="md" />
                </div>
              </Col>
            </Row>
          ) : serviceListError ? (
            <div className="d-flex flex-column justify-center list-group-item align-center w-100 h-100 my-1 px-2">
              <img src={activeVariable== "CRM" ? placeHolderImg :placeHolderImg1 } alt={`No Found Image`} className="mb-1" style={{ width: "150px" }} />
            </div>
          ) : (
            <>
              {serviceList?.status === false ? (
                <Alert color="white" className="text-center">
                  <h4 className="alert-heading">Oops! Something went wrong.</h4>
                  <p>
                    We're sorry, but we couldn't get the Services for you at the moment. Please try again later or click
                    the button below to refresh the page and try again.
                  </p>
                  <Button color="primary" onClick={() => window.location.reload()}>
                    Refresh
                  </Button>
                </Alert>
              ) : serviceList?.data?.Services.length < 0 ? (
                <div className="d-flex justify-center align-center h-100px fs-5">No Services Found</div>
              ) : (
                serviceList?.data?.Services.map((item, idx) => (
                  <div class="col-auto col-sm-6 col-lg-4 my-3 justify-center" key={idx}>
                    <div class="cardUI px-4 py-3 pb-2">
                      <div class="card-detailsUI">
                        <div class="d-flex align-center mb-2 text-titleUI">
                          <div class="fs-1 align-center">
                            <Icon name="play-circle"></Icon>
                          </div>
                          <div class="fs-16px mx-1 fw-semibold">{item?.title}</div>
                        </div>

                        <div>{item?.description}</div>
                      </div>
                      <button class="card-buttonUI btn-primary" onClick={() => navigate("/profiles/1")}>
                        {about?.[activeVariable]?.section2?.map((item) => item?.buttontext)}
                      </button>
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceCards;
