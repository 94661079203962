import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  Button,
  ButtonGroup,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  UncontrolledDropdown,
} from "reactstrap";
import ListView from "./ListView";
import ImagesView from "./ImagesView";
import Icon from "../../components/icon/Icon";
import MapView from "./MapView";
import PaginationComponent from "../../components/pagination/Pagination";
import { getAllCompanies } from "../../http/get/getApi";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { TooltipComponent } from "../../components/Component";
import { getCookie } from "../../utils/Utils";
import book from "../../jsonfiles/Booking.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import Listphotographer from "../../Photography/Studio/Listphotographer";
import TopPhotographers from "../../Photography/HomePage/TopPhotographers";

const ProfileList = ({
  fetchedCompaniesList,
  currentPage,
  setCurrentPage,
  companiesListLoading,
  handlePageChange,
  profilesPerPage,
  dJCompany,
  setMapView,
}) => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [view, setView] = useState("list");
  const [loading, setLoading] = useState(false); // To manage loading state
  const [error, setError] = useState(null); // To manage error state
  const [sortLabel, setSortLabel] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [sortDropDown, setSortDropDown] = useState(false);
  const [sortDropDownVal, setSortDropDownVal] = useState("");
  const sortDropdownRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openRating, setOpenRating] = useState(false);
  const [starRating, setStarRating] = useState("");

  let getCookieCity;
  useEffect(() => {
    getCookieCity = getCookie("latLongCity");
    if (sortLabel === "3" && navigator.geolocation) {
      setSortDropDownVal(getCookieCity);
    } else {
      setSortDropDownVal("");
    }
  }, [sortLabel]);

  const activeVariable = getActiveEnvironmentVariable();

  useEffect(() => {
    if (fetchedCompaniesList?.data) {
      const parsedData = fetchedCompaniesList.data.map((profile) => ({
        ...profile,
        images: profile.images ? JSON.parse(profile.images) : {},
      }));
      setData(parsedData);
      setTotalCount(fetchedCompaniesList.total);

      if (view !== "map") {
        setTotalPages(Math.ceil(fetchedCompaniesList.total / profilesPerPage));
      } else {
        setTotalPages(1);
      }
    }
  }, [fetchedCompaniesList, profilesPerPage, view]);

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(totalPages);
    }
  }, [totalPages, currentPage, setCurrentPage]);

  const handleViewChange = async (viewType) => {
    setView(viewType);
    setCurrentPage(1);
    setMapView(viewType);

    if (viewType === "map") {
      try {
        setLoading(true);
        setError(null);

        const companies = await getAllCompanies({
          type: "",
        });

        const parsedData = companies.data.map((profile) => ({
          ...profile,
          images: profile.images ? JSON.parse(profile.images) : {},
        }));

        setData(parsedData);
        setTotalCount(companies.total);
        setLoading(false);
      } catch (error) {
        setError("Failed to load companies data");
        setLoading(false);
      }
    }
    setOpen(false);
  };

  const handleViewMap = (viewType) => {
    setView(viewType);
    setMapView(viewType);
  };

  const toggle = () => {
    setOpen(!open);
  };

  const toggleRating = () => {
    setOpenRating(!openRating);
  };

  const handleSetSort = (e) => {
    const value = e.target.value;
    setSortLabel(value);

    setSortDropDownVal("");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sortDropdownRef.current && !sortDropdownRef.current.contains(event.target)) {
        setSortDropDown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSortSelect = () => {
    setSortDropDown(true);
  };

  const handleSortDropValue = (value) => {
    const sortvalue =
      value === 1
        ? "Ascending"
        : value === 2
        ? "Descending"
        : value === 3
        ? "High To Low"
        : value === 4
        ? "Low To High"
        : "";
    setSortDropDownVal(sortvalue);
    setSortDropDown(false);
  };

  const handleSortRating = (value) => {
    setStarRating(value);
    setOpenRating(false);
  };

  return (
    <div className="px-0">
      <div
        style={view === "map" ? { position: "absolute", top: "67px", zIndex: "9999", right: "-5px" } : {}}
        className={view === "map" ? "d-flex justify-content-between my-2 mx-1" : "d-flex justify-content-between mx-1"}
      >
        
        
       {( view === "map" ? (
        <h5 className={view === "map" ? "d-none" : "align-center m-0 mx-4"}>
          {book?.[activeVariable]?.section1?.map((item) => item?.text1)}
        </h5>
      ) : totalCount < 0 && loading ? (
        <>
          <ShimmerThumbnail height={30} width={50} className="m-2" rounded />
        </>
      ) : (
        <h5 className="align-center m-0">
          {/* {totalCount || 0} {totalCount <= 1 ? "Result" : "Results"} */}
          {totalCount}{" "}
          {console.log("totalCount",totalCount)}
          {totalCount || totalCount === 0 ? (
            "Results"
          ) : (
            <ShimmerThumbnail height={30} width={50} className="m-2" rounded />
          )}
        </h5>
      )) }
        <div className="d-flex gx-2 pt-1">
          <div className="d-flex d-none">
            <div className="form-control-wrap">
              <div className="form-control-select">
                <select
                  className="form-control form-select"
                  placeholder="Select Sort"
                  onChange={(e) => handleSetSort(e)}
                >
                  {view === "map" ? (
                    <>
                      <option value="3">Near Me</option>
                    </>
                  ) : (
                    <>
                      <option label="Select Sort" value=""></option>
                      <option value="1">Popular</option>
                      <option value="2">Top Rated</option>
                      <option value="4">Price</option>
                      <option value="3">Near Me</option>
                    </>
                  )}
                </select>
              </div>
            </div>
            <div className="form-control-wrap justify-center">
              <Input
                style={{ cursor: "pointer" }}
                id="sortval"
                size="sm"
                type="text"
                readOnly
                disabled={!sortLabel || sortLabel === "1" || sortLabel === "3"}
                value={sortDropDownVal}
                onClick={handleSortSelect}
                className="flex-grow-1 rounded-2 mx-1"
                placeholder={sortLabel === "3" ? "Enable the Location" : "Select Sort Value"}
              />
              {sortLabel === "1" || sortLabel === "3" || sortLabel === "" ? (
                <TooltipComponent
                  id="sortval"
                  text={
                    sortLabel === "3" && sortDropDownVal
                      ? "Result is not show, please refresh the page"
                      : sortLabel === "3" && !sortDropDownVal
                      ? "Enable the location & refresh the current page"
                      : "Only active for Top Rated & Price Sort"
                  }
                  direction="top"
                />
              ) : (
                ""
              )}
              {sortDropDown && (
                <div
                  ref={sortDropdownRef}
                  className="position-absolute ps-1 heromanual-dropdown"
                  style={{ zIndex: 111, width: "95%", top: "100%" }}
                >
                  <DropdownMenu className="d-flex flex-wrap p-1 border w-100">
                    <div className="d-flex flex-column w-100 ">
                      {sortLabel === "2" ? (
                        <>
                          <div
                            value="1"
                            onClick={() => handleSortDropValue(1)}
                            style={{ cursor: "pointer" }}
                            className="w-100 dropdown-item"
                          >
                            Ascending
                          </div>
                          <div
                            value="2"
                            onClick={() => handleSortDropValue(2)}
                            style={{ cursor: "pointer" }}
                            className="w-100 dropdown-item"
                          >
                            Descending
                          </div>
                        </>
                      ) : sortLabel === "4" ? (
                        <>
                          <div
                            value="3"
                            onClick={() => handleSortDropValue(3)}
                            style={{ cursor: "pointer" }}
                            className="w-100 dropdown-item"
                          >
                            High To Low
                          </div>
                          <div
                            value="4"
                            onClick={() => handleSortDropValue(4)}
                            style={{ cursor: "pointer" }}
                            className="w-100 dropdown-item"
                          >
                            Low To High
                          </div>{" "}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </DropdownMenu>
                </div>
              )}
            </div>
            {view === "map" && (
              <Dropdown
                isOpen={openRating}
                toggle={toggleRating}
                className="h-100 bg-white-9 rounded-3 justify-center px-4"
                style={{ width: "30px" }}
                id="sortStar"
              >
                <DropdownToggle
                  tag="a"
                  href="#dropdown"
                  onClick={(ev) => ev.preventDefault()}
                  className="dropdown-toggle w-100 fs-3 justify-center"
                >
                  <div className="icon-status icon-status-na">
                    <Icon name={starRating ? "star-fill" : "star"} className="text-warning"></Icon>
                  </div>
                </DropdownToggle>
                <TooltipComponent
                  id="sortStar"
                  text={starRating ? `${starRating} star rating selected` : "Please select rating"}
                  direction="top"
                />
                <DropdownMenu end className="w-min-120px">
                  <div className="d-flex flex-column align-center">
                    <div
                      value="5"
                      onClick={() => handleSortRating(5)}
                      style={{ cursor: "pointer" }}
                      className="w-100 dropdown-item fs-5"
                    >
                      5{" "}
                      {Array.from({ length: 5 }, (_, index) => (
                        <Icon key={index} name="star-fill" className="text-warning" />
                      ))}
                    </div>
                    <div
                      value="4"
                      onClick={() => handleSortRating(4)}
                      style={{ cursor: "pointer" }}
                      className="w-100 dropdown-item fs-5"
                    >
                      4{" "}
                      {Array.from({ length: 4 }, (_, index) => (
                        <Icon key={index} name="star-fill" className="text-warning" />
                      ))}
                      <Icon name="star" className="text-warning" />
                    </div>
                    <div
                      value="3"
                      onClick={() => handleSortRating(3)}
                      style={{ cursor: "pointer" }}
                      className="w-100 dropdown-item fs-5"
                    >
                      3{" "}
                      {Array.from({ length: 3 }, (_, index) => (
                        <Icon key={index} name="star-fill" className="text-warning" />
                      ))}
                      <Icon name="star" className="text-warning" />
                      <Icon name="star" className="text-warning" />
                    </div>
                    <div
                      value="2"
                      onClick={() => handleSortRating(2)}
                      style={{ cursor: "pointer" }}
                      className="w-100 dropdown-item fs-5"
                    >
                      2 <Icon name="star-fill" className="text-warning" />
                      <Icon name="star-fill" className="text-warning" />
                      {Array.from({ length: 3 }, (_, index) => (
                        <Icon key={index} name="star" className="text-warning" />
                      ))}
                    </div>
                    <div
                      value=""
                      onClick={() => handleSortRating()}
                      style={{ cursor: "pointer" }}
                      className="w-100 dropdown-item fs-6"
                    >
                      <Icon name="star" className="text-warning fs-5" /> Remove Ratings
                    </div>
                  </div>
                </DropdownMenu>
              </Dropdown>
            )}
          </div>
          <div className="d-md-none">
            <Dropdown isOpen={open} toggle={toggle}>
              <DropdownToggle
                tag="a"
                href="#dropdown"
                onClick={(ev) => ev.preventDefault()}
                className="dropdown-toggle nk-quick-nav-icon"
              >
                <div className="icon-status icon-status-na">
                  <Icon name="more-h"></Icon>
                </div>
              </DropdownToggle>
              <DropdownMenu end className="w-min-120px">
                <div className="d-flex flex-column align-center">
                  <span className="my-1 d-flex" style={{ width: "110px" }} onClick={() => handleViewChange("list")}>
                 
                   <Badge
                   color="outline-primary"
                   className={
                     view == "list"
                       ? "fs-5 p-1 rounded-3 justify-center text-primary w-100"
                       : "fs-5 p-1 rounded-3 justify-center text-body w-100 border-0"
                   }
                   style={{ cursor: "pointer" }}
                 >
                   <Icon name="list-round" className="me-1"></Icon>
                   List
                 </Badge>
                   
                  
                  </span>
                  <span className="my-1" style={{ width: "110px" }} onClick={() => handleViewChange("images")}>
                    <Badge
                      color="outline-primary"
                      className={
                        view == "images"
                          ? "fs-5 p-1 rounded-3 justify-center text-primary w-100"
                          : "fs-5 p-1 rounded-3 justify-center text-body w-100 border-0"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Icon name="cards" className="me-1"></Icon>
                      Image
                    </Badge>
                  </span>
                  <span className="my-1" style={{ width: "110px" }} onClick={() => handleViewChange("map")}>
                    <Badge
                      color="outline-primary"
                      className={
                        view == "map"
                          ? "fs-5 p-1 rounded-3 text-primary w-100 justify-center"
                          : "fs-5 p-1 rounded-3 justify-center text-body w-100 border-0"
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Icon name="map-pin" className="me-1"></Icon>
                      Map
                    </Badge>
                  </span>
                </div>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div className={`d-md-block d-none ${view === "map" ? "pt-5" : ""}`}>
           <ButtonGroup className="me-4">
              
              <Button
                color={view === "list" ? "primary" : "light"}
                className={`border-1 border-success-subtle ${view === "list" ? "active" : ""}`}
                onClick={() => handleViewChange("list")}
              >
                <Icon name="list-round" className="me-1 fs-4" />
                List
              </Button>
              <Button
                color={view === "images" ? "primary" : "light"}
                className={`border-1 border-success-subtle ${view === "images" ? "active" : ""}`}
                onClick={() => handleViewChange("images")}
              >
                <Icon name="cards" className="me-1 fs-4" />
                Images
              </Button>
              <Button
                color={view === "map" ? "primary" : "light"}
                className={`border-1 border-success-subtle ${view === "map" ? "active" : ""}`}
                onClick={() => handleViewChange("map")}
              >
                <Icon name="map-pin" className="me-1 fs-4" />
                Map
              </Button>
            </ButtonGroup>
          
            
          </div>
        </div>
      </div>

      {loading ? (
        <>
          <ShimmerThumbnail height={630} width={1500} className="m-2" rounded />
        </>
      ) : error ? (
        <div>{error}</div>
      ) : view === "map" ? (
        <MapView profiles={data} city={data?.[0]?.city} />
      ) : view === "images" ? (
        <div className="mb-3 p-2 rounded">
          {/* {totalCount > 0 ? (
            <div>
              <ImagesView profiles={data} companiesListLoading={companiesListLoading} />
              {totalPages > 0 && (
                <div className="mt-3">
                  {fetchedCompaniesList?.total < 8 ? null : (
                    <PaginationComponent
                      itemPerPage={profilesPerPage}
                      totalItems={totalCount}
                      paginate={handlePageChange}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
              <div className="text-center pt-5 mt-5">
                <h5>No profiles matched your search.</h5>
                <p>Try searching with different criteria or explore the following options</p>
                <ButtonGroup>
                  <Button color="secondary" onClick={() => handleViewMap("map")}>
                    Search Nearby
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          )} */}
          <ImagesView profiles={data} companiesListLoading={companiesListLoading} />
        </div>
      ) : view === "list" ? (
        <div className="mb-3 p-2 rounded">
          {/* {companiesListLoading ? (
            <ShimmerThumbnail height={800} width={1200} className="m-2" rounded />
            
          ) : totalCount > 0 ? ( */}
            <>
              <ListView profiles={data} companiesListLoading={companiesListLoading} dJCompany={dJCompany} />
              {/* <Listphotographer /> */}
              {totalPages > 0 && (
                <div className="mt-3">
                  {fetchedCompaniesList?.total < 8 ? null : (
                    <PaginationComponent
                      itemPerPage={profilesPerPage}
                      totalItems={totalCount}
                      paginate={handlePageChange}
                      currentPage={currentPage}
                    />
                  )}
                </div>
              )}
            </>
          {/* ) : totalPages == 0 ? (
            <div className="d-flex justify-content-center align-items-center mt-5 pt-5">
              <div className="text-center pt-5 mt-5">
                <h5>No profiles matched your search.</h5>
                <p>Try searching with different criteria or explore the following options</p>
                <ButtonGroup>
                  <Button color="primary" onClick={() => handleViewMap("map")}>
                    Search Nearby
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          ) : (
            <ShimmerThumbnail height={800} width={1200} className="m-2" rounded />
          )} */}
        </div>
      ) : null}
    </div>
  );
};

export default ProfileList;
