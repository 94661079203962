import React, { useState, useEffect } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "../../components/Component";
import { useThemeUpdate } from "../../layout/provider/Theme";
import Cookies from "js-cookie";
import data from "../../jsonfiles/Header.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const Navbardropdown = () => {

  const isPhotographyEnabled = process.env.REACT_APP_PHOTOGRAPHY === "true";
  const isCRMEnabled = process.env.REACT_APP_CRM === "true";
  const isConstructionEnabled = process.env.REACT_APP_CONSTRUCTION === "true";
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const [headerLinks, setHeaderLinks] = useState([]);
  const authTokenChk = Cookies.get("authToken");

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen(!open);
  };

  const currentUrl = window.location.pathname || null;

  function setCookieVariable() {
    const expires = new Date(Date.now() + 24 * 60 * 60 * 1000);
    Cookies.set("cookieVal", "clientRole3", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires,
    });
  }
  const activeVariable = getActiveEnvironmentVariable();

  useEffect(() => {
    const activeEnv = getActiveEnvironmentVariable();
    if (activeEnv && data.links[activeEnv]) {
      setHeaderLinks(data.links[activeEnv]);
    } else {
      console.warn("No matching header links found for the active environment.");
    }
  }, []);
  const [isClicked, setIsClicked] = useState(false);

  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#dropdown"
        onClick={(ev) => ev.preventDefault()}
        className="dropdown-toggle nk-quick-nav-icon"
      >
        <div className="icon-status icon-status-na">
          {/* <Icon name="menu-circled" /> */}
          
          <Icon name="menu-circled"  onClick={() => setIsClicked(!isClicked)} />

        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg">
        <div className="dropdown-body">
          <ul className="list-apps">
            {headerLinks.map((link, index) => (
              <li key={index}>
                { isCRMEnabled && 
                (
                  <Link
  to={link.path}
  onClick={toggle}
  className={currentUrl === link.path ? "text-primary nav-link" : "infoSection"}
>
  <span className="list-apps-media" style={{ borderRadius:"50%" }}>
    <Icon name={link.iconname}  />
  </span>
  <span className={currentUrl === link.path ? "text-primary nav-link" : "infoSection"}
 
  >
    {link.name}
  </span>
</Link>
                )
                }
               { isPhotographyEnabled && (
                <Link
  to={link.path}
  onClick={toggle}
  className={currentUrl === link.path ? "text-tertiary nav-link" : "infoSection"}
>
  <span className="list-apps-media" style={{ backgroundColor: '#0056b3', borderRadius:"50%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Icon name={link.iconname} style={{ color: 'blue'}} />
  </span>
  <span className={currentUrl === link.path ? "text-tertiary nav-link" : "infoSection"}
  onMouseOver={(e) => e.target.style.color = '#0056b3'} // Darker blue on hover
  onMouseOut={(e) => e.target.style.color = '#526484'} // Revert back to primary blue
  >
    {link.name}
  </span>
</Link>
) }
 { isConstructionEnabled && (
                <Link
  to={link.path}
  onClick={toggle}
  className={currentUrl === link.path ? "text-tertiary nav-link" : "infoSection"}
>
  <span className="list-apps-media" style={{ backgroundColor: '#FE5D14', borderRadius:"50%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Icon name={link.iconname} style={{ color: 'orange'}} />
  </span>
  <span className={currentUrl === link.path ? "text-tertiary nav-link" : "infoSection"}
  onMouseOver={(e) => e.target.style.color = '#0056b3'} // Darker blue on hover
  onMouseOut={(e) => e.target.style.color = '#526484'} // Revert back to primary blue
  >
    {link.name}
  </span>
</Link>
) }

              </li>
            ))}
            {!authTokenChk && (
              <li className="nav-item">
                {isCRMEnabled && (
                <Link
                  to={`${process.env.REACT_APP_ACCOUNT_URL}login`}
                  className="nav-link"
                  onClick={setCookieVariable}
                >
                  <button className="btn btn-primary">Sign In</button>
                </Link> 
                )}
                
                <Link to={`${process.env.REACT_APP_ACCOUNT_URL}login`} className="nav-link" onClick={setCookieVariable}>
                    <button className="btn btn-primary">Sign In</button>
                  </Link>
              </li>
            )}
          </ul>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Navbardropdown;
