import React from "react";
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import dj from "../../jsonfiles/Dj.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import termscondition from "../../jsonfiles/TermsCondition.json";

const TermsCondition = () => {
  const activeVariable = getActiveEnvironmentVariable();

  return (
    <Container className="mt-5">
      <Card>
        <Card.Body>
          <Card.Title>Terms and Conditions</Card.Title>
          <p>
            Welcome to <strong>{dj?.[activeVariable]?.section5?.map((item) => item?.title2)}</strong>. By accessing or
            using our website, mobile app, or any other service (collectively, the "Platform"), you agree to comply with
            and be bound by the following Terms and Conditions ("T&Cs"). Please read these T&Cs carefully before using
            our Platform.
          </p>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <strong> {termscondition?.[activeVariable]?.section1?.map((item) => item?.section_title)}</strong>
              <p>{termscondition?.[activeVariable]?.section1?.map((item) => item?.title_text)}</p>
              <ul>
                <li>
                  <b>{termscondition?.[activeVariable]?.section1?.map((item) => item?.point1_title)}</b>

                  {termscondition?.[activeVariable]?.section1?.map((item) => item?.point1_text)}
                </li>
                <li>
                  <b>{termscondition?.[activeVariable]?.section1?.map((item) => item?.point2_title)}</b>
                  {termscondition?.[activeVariable]?.section1?.map((item) => item?.point2_text)}
                </li>
                <li>
                  <b>{termscondition?.[activeVariable]?.section1?.map((item) => item?.point3_title)}</b>
                  {termscondition?.[activeVariable]?.section1?.map((item) => item?.point3_text)}
                </li>
              </ul>
              <p>{termscondition?.[activeVariable]?.section1?.map((item) => item?.section1_note)}</p>
            </ListGroup.Item>
            <ListGroup.Item>
              <strong> {termscondition?.[activeVariable]?.section2?.map((item) => item?.section_title)}</strong>
              <ul>
                <li>
                  <b>{termscondition?.[activeVariable]?.section2?.map((item) => item?.point1_title)}</b>
                  {termscondition?.[activeVariable]?.section2?.map((item) => item?.point1_text)}
                </li>
                <li>
                  <b>{termscondition?.[activeVariable]?.section2?.map((item) => item?.point2_title)}</b>
                  {termscondition?.[activeVariable]?.section2?.map((item) => item?.point2_text)}
                </li>
                <li>
                  <b>{termscondition?.[activeVariable]?.section2?.map((item) => item?.point3_title)}</b>
                  {termscondition?.[activeVariable]?.section2?.map((item) => item?.point3_text)}
                </li>
                <li>
                  <b>{termscondition?.[activeVariable]?.section2?.map((item) => item?.point4_title)}</b>
                  {termscondition?.[activeVariable]?.section2?.map((item) => item?.point4_text)}
                </li>
                <li>
                  <b>{termscondition?.[activeVariable]?.section2?.map((item) => item?.point5_title)}</b>
                  {termscondition?.[activeVariable]?.section2?.map((item) => item?.point5_text)}
                </li>
              </ul>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{termscondition?.[activeVariable]?.section3?.map((item) => item?.section_title)}</strong>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section3?.map((item) => item?.point1_title)}</strong>
                <ul>
                  <li>{termscondition?.[activeVariable]?.section3?.map((item) => item?.point1_text)}</li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section3?.map((item) => item?.point2_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section3?.map((item) => item?.point2_text1)}</p>
                <ul>
                  <li>{termscondition?.[activeVariable]?.section3?.map((item) => item?.point2_text2)}</li>
                </ul>
                <p>
                  <li>{termscondition?.[activeVariable]?.section3?.map((item) => item?.point2_text3)}</li>
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section3?.map((item) => item?.point3_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section3?.map((item) => item?.point3_text)}</p>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{termscondition?.[activeVariable]?.section4?.map((item) => item?.section_title)}</strong>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section4?.map((item) => item?.point1_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section4?.map((item) => item?.point1_text1)}</p>
                <ul>
                  <li>{termscondition?.[activeVariable]?.section4?.map((item) => item?.point1_text2)}</li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section4?.map((item) => item?.point2_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section4?.map((item) => item?.point2_text1)}</p>
                <ol>
                  <li>{termscondition?.[activeVariable]?.section4?.map((item) => item?.point2_text2)}</li>
                  <li>{termscondition?.[activeVariable]?.section4?.map((item) => item?.point2_text3)}</li>
                </ol>
              </ListGroup.Item>

              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section4?.map((item) => item?.point3_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section4?.map((item) => item?.point2_text)}</p>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{termscondition?.[activeVariable]?.section5?.map((item) => item?.section_title)}</strong>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point1_title)} </strong>
                <p>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point1_text1)}</p>
                <ul>
                  <li>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point1_text2)}</li>
                </ul>
                <p>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point1_text3)}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point2_title)} </strong>
                <p>
                  <p>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point2_text)}</p>
                </p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point3_title)} </strong>
                <ul>
                  <li>
                    <b>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point3_subpoint1)}</b>
                    {termscondition?.[activeVariable]?.section5?.map((item) => item?.point3_subpoint1_text)}
                  </li>
                  <li>
                    <b>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point3_subpoint2)}</b>
                    {termscondition?.[activeVariable]?.section5?.map((item) => item?.point3_subpoint2_text)}
                  </li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point4_title)} </strong>
                <p>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point4_text)}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point5_title)} </strong>
                <p>{termscondition?.[activeVariable]?.section5?.map((item) => item?.point5_text)}</p>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{termscondition?.[activeVariable]?.section6?.map((item) => item?.section_title)}</strong>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section6?.map((item) => item?.point1_title)} </strong>
                <p>{termscondition?.[activeVariable]?.section6?.map((item) => item?.point1_text1)}</p>
                <ul>
                  <li>
                    <b>{termscondition?.[activeVariable]?.section6?.map((item) => item?.point1_subpoint1)} </b>{" "}
                    {termscondition?.[activeVariable]?.section6?.map((item) => item?.point1_subpoint1_title)}
                  </li>
                  <li>
                    <b>{termscondition?.[activeVariable]?.section6?.map((item) => item?.point1_subpoint2)} </b>{" "}
                    {termscondition?.[activeVariable]?.section6?.map((item) => item?.point1_subpoint2_title)}
                  </li>
                </ul>
                <p>{termscondition?.[activeVariable]?.section6?.map((item) => item?.point1_text2)}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section6?.map((item) => item?.point2_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section6?.map((item) => item?.point2_text)}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section6?.map((item) => item?.point3_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section6?.map((item) => item?.point3_text)}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section6?.map((item) => item?.point4_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section6?.map((item) => item?.point4_text)}</p>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{termscondition?.[activeVariable]?.section7?.map((item) => item?.section_title)}</strong>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point1_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point1_text1)}</p>
                <ul>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point1_text2)}</li>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point1_text3)}</li>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point1_text4)}</li>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point1_text5)}</li>
                </ul>
                <p>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point1_text6)}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point2_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point2_text1)}</p>
                <ul>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point2_text2)}</li>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point2_text3)}</li>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point2_text4)}</li>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point2_text5)}</li>
                </ul>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point3_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point3_text1)}</p>
                <ul>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point3_text2)}</li>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point3_text3)}</li>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point3_text4)}</li>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point3_text5)}</li>
                  <li>{termscondition?.[activeVariable]?.section7?.map((item) => item?.point3_text6)}</li>
                </ul>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{termscondition?.[activeVariable]?.section8?.map((item) => item?.section_title)}</strong>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section8?.map((item) => item?.point1_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section8?.map((item) => item?.point1_text)}</p>
              </ListGroup.Item>
              <ListGroup.Item>
                <strong>{termscondition?.[activeVariable]?.section8?.map((item) => item?.point2_title)}</strong>
                <p>{termscondition?.[activeVariable]?.section8?.map((item) => item?.point2_text)}</p>
              </ListGroup.Item>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{termscondition?.[activeVariable]?.section9?.map((item) => item?.section_title)}</strong>
              <p>{termscondition?.[activeVariable]?.section9?.map((item) => item?.point1_text1)}</p>
              <ul>
                <li>{termscondition?.[activeVariable]?.section9?.map((item) => item?.point1_text2)}</li>
                <li>{termscondition?.[activeVariable]?.section9?.map((item) => item?.point1_text3)}</li>
                <li>{termscondition?.[activeVariable]?.section9?.map((item) => item?.point1_text4)}</li>
              </ul>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{termscondition?.[activeVariable]?.section10?.map((item) => item?.section_title)}</strong>
              <p>{termscondition?.[activeVariable]?.section10?.map((item) => item?.section_text)}</p>
            </ListGroup.Item>

            <ListGroup.Item>
              <strong>{termscondition?.[activeVariable]?.section11?.map((item) => item?.section_title)}</strong>
              <p>{termscondition?.[activeVariable]?.section11?.map((item) => item?.point1_text1)}</p>
              <strong>{termscondition?.[activeVariable]?.section11?.map((item) => item?.point1_text2)}</strong>
              <p className="mb-0">
                <b>Email:{" "}</b>
                {/* <a href="mailto:support@ipartydjmix.com">
                  {termscondition?.[activeVariable]?.section11?.map((item, index) => (
                    <span key={index}>{item?.email}</span>
                  ))}
                </a> */}
                <a
  href={`mailto:${termscondition?.[activeVariable]?.section11?.[3]?.email || ''}`}
>
  {termscondition?.[activeVariable]?.section11?.map((item, index) => (
    <span key={index}>{item?.email}</span>
  ))}
</a>
              </p>
              <p className="mb-0">
                <b>Phone:</b>
                {termscondition?.[activeVariable]?.section11?.map((item) => item?.phone)}
              </p>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TermsCondition;
