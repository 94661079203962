import React, { useEffect, useState, useRef, Suspense } from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
// import HTMLFlipBook from "react-pageflip";
// import "./App.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import "react-circular-progressbar/dist/styles.css";
// import samplepdf from "../../Noitavonne HR POLICIES Final.pdf";
import Flipbook from "./Flipbook";
// import flipbokcomponent from "../components/"
// import React, { useEffect, useRef, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
} from "../../components/Component";
import {
  Badge,
  Card,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Content from "../../layout/content/Content";
import classnames from "classnames";
import placeHolderImg from "../../assets/images/placeHolderImg.png";
import ReactPlayer from "react-player";
import "../../css/style.css";
import thumbnail from "../../images/ThumbnailImage.jpg";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import Fancybox from "./Fancybox";
import NoResult from "../../images/placeholderimageforallproject.jpg";
import NoResultVideo from "../../images/NoResultVideo.png";
import NoResultVideo1 from "../../images/placeholderimageforallproject.jpg";
import NoResultMixes from "../../images/NoResultMixes.png";
import NoResultMixes1 from "../../images/placeholderimageforallproject.jpg";

import portfolio from "../../jsonfiles/Portfolio.json";
import book from "../../jsonfiles/Booking.json";
import dj from "../../jsonfiles/Dj.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

// 3D
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Html } from "@react-three/drei"; // Import Html for loading text
import { Canvas, useThree, useFrame } from "@react-three/fiber"; // Import Canvas from react-three/fiber
import { OrbitControls } from "@react-three/drei";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { getAllCompanies, getModelAndCards } from "../../http/get/getApi";
import { useQuery } from "react-query";
import { useParams } from "react-router";
// import { useThree, useFrame } from '@react-three/fiber';
import { Raycaster, Vector2 } from "three";

const Portfolio = ({ companyData, companyDataLoading }) => {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("1");
  const [headerLinks, setHeaderLinks] = useState([]);
  const [activeVideo, setActiveVideo] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryChk, setSelectedCategoryChk] = useState("All");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [videosData, setVideosData] = useState([]);
  const [videoOpen, setVideoOpen] = useState(false);
  const [videoValue, setVideoValue] = useState(null);
  const [mixersValue, setMixersValue] = useState([]);
  const [soundMixersValue, setSoundMixersValue] = useState([]);
  const [mixCloudMixersValue, setMixCloudMixersValue] = useState([]);
  const [selectedMixesChk, setSelectedMixesChk] = useState("Mixes");
  const [playingMix, setPlayingMix] = useState(null);
  const [mode, setMode] = useState(null);

  const togglevid = () => setVideoOpen(!videoOpen);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const categories = companyData?.images ? Object.keys(companyData.images) : [];
  const filteredImages = companyData?.images ? companyData?.images : {};

  const handleCategorySelect = (category) => {
    setSelectedCategory(category === "All" ? null : category);
    setSelectedCategoryChk(category);
  };
  const handleVideoClick = (index) => {
    setActiveVideo(index === activeVideo ? null : index);
  };

  const closeVideoModal = () => {
    setActiveVideo(null);
  };

  useEffect(() => {
    const uploadVideo = companyData?.videos;
    if (uploadVideo) {
      setVideosData(uploadVideo);
    } else {
      setVideosData([]);
    }
  }, [companyData]);

  useEffect(() => {
    const uploadMixers = companyData?.mixesData?.[0]?.mixes || [];
    if (uploadMixers) {
      setMixersValue(uploadMixers);
    } else {
      setMixersValue([]);
    }
  }, [companyData]);

  useEffect(() => {
    const uploadMixersLinks = companyData?.mixesData?.[0]?.soundcloud || [];
    try {
      setSoundMixersValue(uploadMixersLinks);
    } catch (error) {
      console.error("Failed to parse mixers links:", error);
      setSoundMixersValue([]);
    }
  }, [companyData]);

  useEffect(() => {
    const uploadMixersLinks = companyData?.mixesData?.[0]?.mixcloud || [];
    try {
      setMixCloudMixersValue(uploadMixersLinks);
    } catch (error) {
      console.error("Failed to parse mixers links:", error);
      setMixCloudMixersValue([]);
    }
  }, [companyData]);

  const handleMixesSelect = (mixcategory) => {
    setSelectedMixesChk(mixcategory);
  };
  const handleMixPlay = (index) => {
    setPlayingMix(index);
  };

  const activeVariable = getActiveEnvironmentVariable();

  useEffect(() => {
    const activeEnv = getActiveEnvironmentVariable();
    if (activeEnv && portfolio.links[activeEnv]) {
      setHeaderLinks(portfolio.links[activeEnv]);
    } else {
      console.warn("No matching header links found for the active environment.");
    }
  }, []);

  // 3D
  const [selectedCard, setSelectedCard] = useState(null); // To store the selected card
  const modalCanvasRef = useRef(null); // Ref for the modal canvas to render the 3D model
  console.log("selcted", selectedCard);
  // Mouse coordinates for raycasting

  const { data, isLoading, error } = useQuery({
    queryKey: ["cardsAndModels", id],
    queryFn: () => getAllCompanies({ id: id }),
  });
  console.log("Fetched data:", data);
  // console.log("model data:", data?.data?.threeD_model);

  // const openModal = (card) => {
  //   if (data?.threeD_model && card?.threeD_model.length > 0) {
  //     console.log("Selected card's model URL:", card?.threeD_model[0]?.temporaryUrl);
  //     setSelectedCard(card);
  //   }
  // };
  // const openModal = (card) => {
  //   if (card?.threeD_model?.[0]?.temporaryUrl) {
  //     console.log("Selected card's model URL:", card.threeD_model[0].temporaryUrl); // Check URL
  //     setSelectedCard(card); // Set the entire card, including the 3D model
  //   } else {
  //     console.error("No valid 3D model URL found for the selected card.");
  //   }
  // };
  const openModal = (card) => {
    if (card?.temporaryUrl) {
      console.log("Selected card's model URL:", card.temporaryUrl); // Log URL for debugging
      setSelectedCard(card); // Set the entire card, including the 3D model data
    } else {
      console.error("No valid 3D model URL found for the selected card.");
    }
  };
  
  
  const closeModal = () => setSelectedCard(null);

  // Model component to load and display the 3D model
  const Model = ({ modelUrl }) => {
    const [model, setModel] = useState(null);
    const [loading, setLoading] = useState(true);
    console.log("model", modelUrl);

    useEffect(() => {
      const fileExtension = modelUrl?.split(".").pop().toLowerCase().split("?")[0]; // Get the file extension
      console.log(fileExtension);
      let loader;
      // loader = new GLTFLoader();
      // Select the appropriate loader based on file extension
      if (fileExtension === "fbx") {
        loader = new FBXLoader();
      } else if (fileExtension === "obj") {
        loader = new OBJLoader();
      } else if (fileExtension === "gltf" || fileExtension === "glb") {
        loader = new GLTFLoader();
      } else {
        console.error("Unsupported model format:", fileExtension);
        setLoading(false);
        return;
      }

      setLoading(true);
      loader.load(
        modelUrl,
        (loadedModel) => {
          setModel(fileExtension === "obj" ? loadedModel : loadedModel.scene || loadedModel);
          setLoading(false);
        },
        undefined,
        (err) => {
          console.error("Error loading the model:", err);
          setLoading(false);
        }
      );
    }, [modelUrl]);

    if (loading) {
      return (
        <Html center>
          <div style={{ color: "black", fontSize: "1.5rem", textAlign: "center" }}>
            <div className="spinner-border text-black" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <p>Loading Model...</p>
          </div>
        </Html>
      );
    }

    if (!model) {
      return (
        <Html center>
          <div style={{ color: "red", fontSize: "1.5rem", textAlign: "center" }}>Failed to load model.</div>
        </Html>
      );
    }

    return <primitive object={model} position={[0, -1, 0]} scale={[1, 1, 1]} />;
  };
  // Return loading/error states
  if (isLoading) return <div>Loading cards...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <Block>
        <PreviewCard>
          <div id="portfolio">
            <BlockHead size="sm" className="my-2 mx-2">
              <BlockBetween className="g-3 justify-center">
                <BlockHeadContent>
                  <BlockTitle page>
                    <h3 className="text-center">Portfolio</h3>
                  </BlockTitle>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>

            <Nav tabs className="mt-n3 justify-center">
              {headerLinks.map((link) => (
                <NavItem key={link.id}>
                  <NavLink
                    tag="a"
                    href="#tab"
                    className={classnames({ active: activeTab === link.id })}
                    onClick={(ev) => {
                      ev.preventDefault();
                      toggle(link.id);
                    }}
                  >
                    {link.name}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>

            <TabContent activeTab={activeTab} className="p-0 m-0">
              <TabPane tabId="1">
                <Content>
                  <Block>
                    <div>
                      {mixersValue.length === 0 ? (
                        ""
                      ) : (
                        <span onClick={() => handleMixesSelect("Mixes")}>
                          <Badge
                            color="outline-primary"
                            className={
                              selectedMixesChk == "Mixes"
                                ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                                : "fs-5 me-1 p-1 rounded-5 px-4 hovereffct"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {book?.[activeVariable]?.section3?.map((item) => item?.text1)}
                          </Badge>
                        </span>
                      )}
                      {soundMixersValue.length === 0 ? (
                        ""
                      ) : (
                        <span onClick={() => handleMixesSelect("Sound-Cloud")}>
                          <Badge
                            color="outline-primary"
                            className={
                              selectedMixesChk == "Sound-Cloud"
                                ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                                : "fs-5 me-1 p-1 rounded-5 px-4 hovereffct"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {book?.[activeVariable]?.section3?.map((item) => item?.text2)}
                          </Badge>
                        </span>
                      )}
                      {mixCloudMixersValue.length === 0 ? (
                        ""
                      ) : (
                        <span onClick={() => handleMixesSelect("Mix-Cloud")}>
                          <Badge
                            color="outline-primary"
                            // color="light"
                            className={
                              selectedMixesChk == "Mix-Cloud"
                                ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                                : "fs-5 me-1 p-1 rounded-5 px-4 hovereffct"
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {book?.[activeVariable]?.section3?.map((item) => item?.text3)}
                          </Badge>
                        </span>
                      )}
                    </div>
                    {selectedMixesChk === "Mixes" ? (
                      <Row className="g-3 mt-1">
                        {mixersValue &&
                          mixersValue.map((mixer, index) => (
                            <Col sm={6} lg={4} xxl={3} key={index} className="justify-center">
                              <Card
                                className="card-bordered gallery align-center w-100 border-0"
                                style={{ cursor: "pointer" }}
                              >
                                <div className="cardaudio w-85 justify-center">
                                  <div className="topaudio">
                                    <div className="pfp">
                                      <div className="playingaudio">
                                        <div className="greenlineaudio line-1audio"></div>
                                        <div className="greenlineaudio line-2audio"></div>
                                        <div className="greenlineaudio line-3audio"></div>
                                        <div className="greenlineaudio line-4audio"></div>
                                        <div className="greenlineaudio line-5audio"></div>
                                      </div>
                                    </div>
                                    <div className="textsaudio">
                                      <p className="title-1audio">{mixer.title || `Audio - ${index + 1}`}</p>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <ReactPlayer
                                      playing={
                                        selectedMixesChk === "Mixes" && activeTab === "1" && index === playingMix
                                      }
                                      onPlay={() => handleMixPlay(index)}
                                      url={mixer?.path}
                                      controls={true}
                                      width="100%"
                                      height="30px"
                                    />
                                  </div>
                                </div>
                              </Card>
                            </Col>
                          ))}
                        {!mixersValue.length && (
                          <div className="d-flex justify-content-center align-items-center">
                            <Card className="p-2">
                              <div className="justify-center  ">
                                <img src={activeVariable== "CRM" ? NoResultMixes :NoResultMixes1} alt="No-Result" height="200px" />
                              </div>
                            </Card>
                          </div>
                        )}
                      </Row>
                    ) : selectedMixesChk === "Sound-Cloud" ? (
                      <Row className="g-3 mt-1">
                        {companyDataLoading ? (
                          <div>
                            <ShimmerSimpleGallery card imageHeight={100} />
                          </div>
                        ) : soundMixersValue.length > 0 ? (
                          soundMixersValue.map((mixer, index) => (
                            <Col sm={6} lg={4} xxl={3} key={index} className="justify-center">
                              <div className="mt-2 w-100" dangerouslySetInnerHTML={{ __html: mixer.mixlink }} />
                            </Col>
                          ))
                        ) : (
                          <div className="d-flex justify-content-center align-items-center">
                            <Card className="p-2">
                              <div className="justify-center  ">
                                <img src={activeVariable== "CRM" ? NoResultMixes :NoResultMixes1} alt="No-Result" height="200px" />
                              </div>
                            </Card>
                          </div>
                        )}
                      </Row>
                    ) : selectedMixesChk === "Mix-Cloud" ? (
                      <Row className="g-3 mt-1">
                        {companyDataLoading ? (
                          <div>
                            <ShimmerSimpleGallery card imageHeight={100} />
                          </div>
                        ) : mixCloudMixersValue.length > 0 ? (
                          mixCloudMixersValue.map((mixer, index) => (
                            <Col sm={6} lg={4} xxl={3} key={index} className="justify-center">
                              <div className="mt-2 w-100" dangerouslySetInnerHTML={{ __html: mixer.mixlink }} />
                            </Col>
                          ))
                        ) : (
                          <div className="d-flex justify-content-center align-items-center">
                            <Card className="p-2">
                              <div className="justify-center  ">
                                <img src={activeVariable== "CRM" ? NoResultMixes :NoResultMixes1} alt="No-Result" height="200px" />
                              </div>
                            </Card>
                          </div>
                        )}
                      </Row>
                    ) : (
                      ""
                    )}
                  </Block>
                </Content>
              </TabPane>
              <TabPane tabId="2">
                <Content>
                  <Block>
                    <Row className="g-3 mt-1">
                      {videosData.map((video, index) => (
                        <Col sm={6} lg={4} xxl={3} key={index} className="justify-center">
                          <Card
                            className="card-bordered gallery align-center w-85 border-0"
                            style={{ cursor: "pointer" }}
                          >
                            <div className="video justify-center">
                              <div className="player-wrapper">
                                <img
                                  className="video-poster"
                                  src={video.thumbnail ? video.thumbnail : thumbnail}
                                  alt="DJ Video"
                                />
                               
                                <div
                                  className="video-play popup-video start-0 video-modal"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    togglevid();
                                    setVideoValue(video?.path);
                                  }}
                                >
                                  <div class="vidbutton">
                                    <svg
                                      viewBox="0 0 448 512"
                                      xmlns="http://www.w3.org/2000/svg"
                                      aria-hidden="true"
                                      width="20px"
                                    >
                                      <path
                                        d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </Col>
                      ))}
                      {videosData.length === 0 && (
                        <div className="d-flex justify-content-center align-items-center">
                          <Card className="p-2">
                            <div className="justify-center  ">
                              <img src={activeVariable== "CRM" ? NoResultVideo :NoResultVideo1} alt="No-Result" height="200px" />
                            </div>
                          </Card>
                        </div>
                      )}
                    </Row>
                  </Block>
                </Content>
              </TabPane>
              <TabPane tabId="3">
                <Content>
                  <Block>
                    {companyData?.images ? (
                      <div>
                        {companyData?.images.length === 0 ? (
                          ""
                        ) : (
                          <div>
                            <span onClick={() => handleCategorySelect("All")}>
                              <Badge
                                color="outline-primary"
                                className={
                                  selectedCategoryChk == "All"
                                    ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                                    : "fs-5 me-1 p-1 rounded-5 px-4 hovereffct"
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {book?.[activeVariable]?.section3?.map((item) => item?.text4)}
                              </Badge>
                            </span>
                            {categories.map((category, index) => (
                              <span key={index} onClick={() => handleCategorySelect(category)}>
                                <Badge
                                  color="outline-primary"
                                  className={
                                    selectedCategoryChk == category
                                      ? "fs-5 me-1 p-1 rounded-5 px-4 text-primary"
                                      : "fs-5 me-1 p-1 rounded-5 px-4 hovereffct"
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {category}
                                </Badge>
                              </span>
                            ))}
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                    <Row className="g-3 mt-3 h-500px overflow-auto" style={{ scrollbarWidth: "none" }}>
                      <Fancybox
                        options={{
                          Carousel: {
                            infinite: false,
                          },
                        }}
                      >
                        {(selectedCategory ? filteredImages[selectedCategory] : Object.values(filteredImages))
                          .flat()
                          .map((imageUrl, index) => (
                            <Col key={index} sm={6} lg={3} xxl={3} className="m-0 p-0">
                              <Card className="gallery">
                                <div
                                  className="col-4 justify-center mt-0 w-100"
                                  key={index}
                                  style={{ padding: "1px 1px", height: "40vh" }}
                                >
                                  {" "}
                                  <a
                                    data-fancybox="gallery"
                                    href={imageUrl?.url || NoResult}
                                    className="justify-center h-100 w-100"
                                  >
                                    <img
                                      alt={dj?.[activeVariable]?.section8?.map((item) => item?.alt2)}
                                      src={imageUrl?.url || NoResult}
                                      className="object-fit-cover w-100"
                                      // style={{ height: "200px" }}
                                    />
                                  </a>
                                </div>
                              </Card>
                            </Col>
                          ))}
                      </Fancybox>
                      {Object.keys(filteredImages).length === 0 && (
                        <div className="d-flex justify-content-center align-items-center w-100">
                          <Card className="p-2">
                            <div className="justify-center">
                              <img src={NoResult} alt="No-Result" height="200px" />
                            </div>
                          </Card>
                        </div>
                      )}
                    </Row>
                  </Block>
                </Content>
              </TabPane>
              <TabPane tabId="4">
                <div d-flex justify-content-center align-items-center>
                  <Flipbook mode={null} />
                </div>
              </TabPane>
              <TabPane tabId="5">
                <>
                  <div className="container" style={{ marginTop: "20px" }}>
                    <div className="row" style={{ marginLeft: "-50px" }}>
                      {/* Check if there are any cards with 3D models */}
                      {data?.data?.[0]?.threeD_model?.length > 0 ? (
                        data.data[0].threeD_model.map((card, index) => (
                          <div key={card.id || index} className="col-md-6 col-lg-3"
                          onClick={() => openModal(card)}>
                            <div
                              className="card-3d mb-3"
                              style={{
                                width: "100%",
                                height: "300px",
                                padding: "10px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                borderRadius: "10px",
                                overflow: "hidden",
                              }}
                            >
                              {/* Display thumbnail */}
                              <img
                                src={card?.thumbnail || "https://via.placeholder.com/150"} // Provide a default image if thumbnail is null
                                alt={card?.title || "3D Model Thumbnail"} // Provide a default alt text
                                style={{
                                  width: "100%",
                                  height: "200px",
                                  objectFit: "cover",
                                  borderRadius: "10px",
                                  marginBottom: "10px",
                                }}
                              />
                              {/* Display title and description */}
                              <h5 className="card-title-3d text-center">
                                {card?.title || ""} {/* Fallback for null title */}
                              </h5>
                              <p className="card-description-3d text-center">
                                {card?.description || ""} {/* Fallback for null description */}
                              </p>
                            </div>
                          </div>
                        ))
                      ) : (
                        // Fallback for no 3D models
                        <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
                          <Card className="p-2">
                            <div className="justify-center">
                              <img src={NoResult} alt="No 3D Models" height="200px" />
                            </div>
                          </Card>
                        </div>
                      )}
                    </div>

                    {/* Modal for the selected card */}
                    {selectedCard && (
                      <div className="modal fade show" tabIndex="-1" style={{ display: "block" }} onClick={closeModal}>
                        <div className="modal-dialog modal-lg" onClick={(e) => e.stopPropagation()}>
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">{selectedCard.title || ""}</h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={closeModal}
                              ></button>
                            </div>
                            <div className="modal-body">
                              {/* Canvas for 3D model inside the modal */}
                              <div className="canvas-container">
                                <Canvas style={{ width: "100%", height: "100%", touchAction: "auto" }}>
                                  <Suspense
                                    fallback={
                                      <Html center>
                                        <div style={{ color: "black" }}>Loading...</div>
                                      </Html>
                                    }
                                  >
                                    <ambientLight intensity={0.3} />
                                    <directionalLight position={[5, 10, 7]} intensity={0.7} />
                                    <pointLight position={[-10, -10, -10]} intensity={0.5} />
                                    <Model modelUrl={selectedCard?.temporaryUrl} />
                                    <OrbitControls
                                      enableZoom={true}
                                      onEnd={(e) => {
                                        const controls = e.target;

                                        // Snapping logic
                                        const snapToAngle = (angle) => {
                                          const snapIncrement = Math.PI / 4; // Snap to 45° increments
                                          return Math.round(angle / snapIncrement) * snapIncrement;
                                        };

                                        controls.azimuthalAngle = snapToAngle(controls.azimuthalAngle); // Snap horizontal rotation
                                        controls.polarAngle = snapToAngle(controls.polarAngle); // Snap vertical rotation
                                        controls.update(); // Apply snapped values
                                      }}
                                    />
                                  </Suspense>
                                </Canvas>
                              </div>
                              <hr />
                              <p>{selectedCard.description || ""}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              </TabPane>
            </TabContent>
          </div>
        </PreviewCard>
        <Modal size="lg" isOpen={videoOpen} toggle={togglevid}>
          <ModalHeader
            className="justify-between"
            toggle={togglevid}
            close={
              <button className="close " onClick={togglevid}>
                <Icon name="cross" />
              </button>
            }
          >
            Video Preview
          </ModalHeader>
          <ModalBody className="p-0" style={{ height: "500px" }}>
            {companyDataLoading ? (
              <div>
                <ShimmerSimpleGallery card imageHeight={100} />
              </div>
            ) : (
              <ReactPlayer controls url={videoValue} className="react-player" width="100%" height="100%" />
            )}
          </ModalBody>
        </Modal>
      </Block>
    </div>
  );
};

export default Portfolio;
