import React, { useState } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import samplepdf from "../../Wed Album - Flipbook by SALU MON _ FlipHTML5.pdf";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import FlipBookComponent from "./components/FlipBookComponent";
import { Icon } from "../../components/Component";
import { useParams } from "react-router";
import { useQuery } from "react-query";
import { getpdf } from "../../http/get/getApi";
import NoResults from "../../images/No Result.png";
import NoResults1 from "../../images/placeholderimageforallproject.jpg";
import { getAllCompanies } from "../../http/get/getApi";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
const Flipbook = ({ mode: initial }) => {
  const activeVariable = getActiveEnvironmentVariable();
  const { id } = useParams();
  const { key } = useParams();
  const {
    data: availabelpdf, 
    isLoading,
    isError,    
  } = useQuery({
    queryKey: ["get-pdf", id],
    queryFn: () => getAllCompanies({ id }), // Call your API function
    staleTime: Infinity,
  });   

  console.log("availabelpdf123", availabelpdf?.data?.[0]?.album_pdf);
  // return;
  // const [showOptions, setShowOptions] = useState(false);
  const [showFlipbook, setShowFlipbook] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // const handlePdfClick = () => {
  //   setShowModal(true);
  // };

  // const viewPdf = () => {
  //   setMode("view");
  //   setShowModal(false);
  //   setMode("initial");
  // };
  const [albumPdf, setAlbumPdf] = useState(null);
  const openFlipbook = () => {
    setShowFlipbook(true);
    setShowModal(false);
    const pdf = availabelpdf?.data?.[0]?.album_pdf;

    console.log("availabelpdf123", pdf);
    setAlbumPdf(pdf);
  };

  // const downloadPdf = () => {
  //   const link = document.createElement("a");
  //   link.href = samplepdf;
  //   link.download = "Noitavonne HR POLICIES Final.pdf";
  //   link.click();
  //   // setShowModal(false);
  // };

  const goBack = () => {
    setShowFlipbook(false); // Reset mode to go back to the initial view
  };

  return (
    <div style={{ textAlign: "center" }}>
      {availabelpdf?.data?.[0]?.album_pdf ? (
        showFlipbook ? (
          <div style={{ marginTop: "1px" }}>
            <FlipBookComponent setShowFlipbook={setShowFlipbook} albumPdf={albumPdf} />
          </div>
        ) : (
          <div onClick={openFlipbook} className="file-pdf mt-4" style={{ cursor: "pointer" }}>
            <Icon name="file-pdf" className="text-danger large-icon" />
          </div>
        )
      ) : (
        <div className="text-center my-2 mt-4">
          <img src={activeVariable== "CRM" ? NoResults :NoResults1} height={150} width={190} alt="Image not found"></img>
        </div>
      )}

      {/* <Modal isOpen={showModal} toggle={() => setShowModal(false)} centered>
        <ModalHeader toggle={() => setShowModal(false)}>PDF Tools Menu</ModalHeader>
        <ModalBody>
          <div style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
            <Button onClick={viewPdf} className="me-2">
              <a href={samplepdf} target="_blank" rel="noopener noreferrer">
                PDF Viewer
              </a>
            </Button>
            <Button onClick={convertToFlipbook} className="me-2">
              Convert to Flipbook
            </Button>
            <Button onClick={downloadPdf} color="primary">
              Download PDF
            </Button>
          </div>
        </ModalBody>
      </Modal> */}

      {/* {setShowFlipbook && (
  <div style={{ marginTop: "20px" }} className="d-flex justify-content-start">
    <Button onClick={goBack} color="primary"><Icon name="back-arrow"  className="icon-fix" /></Button>
  </div>
)} */}

      {/* {mode === "view" && (
        <div style={{ marginTop: "20px" }}>
          <div className="d-flex justify-content-end pr-4">
            <Icon name="download" className="text-success small-icon fs-2" onClick={downloadPdf} />
          </div>
          <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`}>
            <div>
              <Viewer fileUrl={samplepdf} />
            </div>
          </Worker>
        </div>
      )}

       */}
    </div>
  );
};

export default Flipbook;
