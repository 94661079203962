import React, { useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import placeHolderImg from "../../images/imageCarousel.png";
import placeHolderImg1 from "../../images/placeholderimageforallproject.jpg";

import { Block, Icon, TooltipComponent } from "../../components/Component";
import Fancybox from "./Fancybox";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AiFillTikTok } from "react-icons/ai";
import { FaMixcloud } from "react-icons/fa";
import { FaTwitch } from "react-icons/fa";
import dj from "../../jsonfiles/Dj.json";
import { getActiveEnvironmentVariable } from "../../utils/Utils";

const ImageCarousel = ({ companyData }) => {
  const activeVariable = getActiveEnvironmentVariable();

  const [modalSm, setModalSm] = useState(false);
  const toggleSm = () => setModalSm(!modalSm);
  const navigate = useNavigate();

  return (
    <>
      <Block>
        <Card className="border-0 mb-4">
          <div className="d-flex justify-between">
            <div className="w-100">
              <div class="cardCompanyTitle btn-primary">
                <div className="imageCompanyTitle">
                  <div class="pfpheader">
                    <div class="playingheader ">
                      <div class="greenlineheader line-1audio "></div>
                      <div class="greenlineheader  line-2audio "></div>
                      <div class="greenlineheader  line-3audio "></div>
                      <div class="greenlineheader  line-4audio "></div>
                      <div class="greenlineheader  line-5audio "></div>
                    </div>
                  </div>
                </div>
                <div class="card-infoCompanyTitle">
                  <span>{companyData?.company_name}</span>
                </div>
              </div>
            </div>
            <div className="d-md-none align-center">
              <Button color="primary" onClick={toggleSm}>
                Visit Social Links
              </Button>
              <Modal size="sm" isOpen={modalSm} toggle={toggleSm}>
                <ModalHeader
                  toggle={toggleSm}
                  close={
                    <button className="close ml-auto" onClick={toggleSm}>
                      <Icon name="cross" />
                    </button>
                  }
                >
                  Social Links
                </ModalHeader>
                <ModalBody>
                  {companyData?.socialmedia?.[0]?.website ? (
                    <div className="my-1">
                      <Link to={companyData?.socialmedia?.[0]?.website} target="_blank">
                        <div className="d-flex align-center">
                          <span className="mx-1">
                            <div class="parentUI">
                              <div class="childUI child-1UI">
                                <button class="buttonUI btn-2UI align-center">
                                  <Icon name="monitor" style={{ color: "#405DE6" }}></Icon>
                                </button>
                              </div>
                            </div>
                          </span>
                          <span className="ms-1 text-body">Website Link</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {companyData?.socialmedia?.[0]?.facebook ? (
                    <div className="my-1">
                      <Link to={companyData?.socialmedia?.[0]?.facebook} target="_blank">
                        <div className="d-flex align-center">
                          <span className="mx-1">
                            <div class="parentUI">
                              <div class="childUI child-1UI">
                                <button class="buttonUI btn-2UI align-center">
                                  <Icon name="facebook-fill" style={{ color: "#405DE6" }}></Icon>
                                </button>
                              </div>
                            </div>
                          </span>
                          <span className="ms-1 text-body">Facebook Link</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {companyData?.socialmedia?.[0]?.instagram ? (
                    <div className="my-1">
                      <Link to={companyData?.socialmedia?.[0]?.instagram} target="_blank">
                        <div className="d-flex align-center">
                          <span className="mx-1">
                            <div class="parentUI">
                              <div class="childUI child-1UI">
                                <button class="buttonUI btn-2UI align-center">
                                  <Icon name="instagram" style={{ color: "#E1306C" }}></Icon>
                                </button>
                              </div>
                            </div>
                          </span>
                          <span className="ms-1 text-body">Instagram Link</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {companyData?.socialmedia?.[0]?.tiktok ? (
                    <div className="my-1">
                      <Link to={companyData?.socialmedia?.[0]?.tiktok} target="_blank">
                        <div className="d-flex align-center">
                          <span className="mx-1">
                            <div class="parentUI">
                              <div class="childUI child-1UI">
                                <button class="buttonUI btn-2UI align-center">
                                  <AiFillTikTok />
                                </button>
                              </div>
                            </div>
                          </span>
                          <span className="ms-1 text-body">Tiktok Link</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {companyData?.socialmedia?.[0]?.linkedin ? (
                    <div className="my-1">
                      <Link to={companyData?.socialmedia?.[0]?.linkedin} target="_blank">
                        <div className="d-flex align-center">
                          <span className="mx-1">
                            <div class="parentUI">
                              <div class="childUI child-1UI">
                                <button class="buttonUI btn-2UI align-center">
                                  <Icon name="linkedin" style={{ color: "#0072b1" }}></Icon>
                                </button>
                              </div>
                            </div>
                          </span>
                          <span className="ms-1 text-body">Linkedin Link</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {companyData?.socialmedia?.[0]?.soundcloud ? (
                    <div className="my-1">
                      <Link to={companyData?.socialmedia?.[0]?.soundcloud} target="_blank">
                        <div className="d-flex align-center">
                          <span className="mx-1">
                            <div class="parentUI">
                              <div class="childUI child-1UI">
                                <button class="buttonUI btn-2UI align-center">
                                  <Icon name="soundcloud" style={{ color: "#ff5500" }}></Icon>
                                </button>
                              </div>
                            </div>
                          </span>
                          <span className="ms-1 text-body">Soundcloud Link</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {companyData?.socialmedia?.[0]?.mixcloud ? (
                    <div className="my-1">
                      <Link to={companyData?.socialmedia?.[0]?.mixcloud} target="_blank">
                        <div className="d-flex align-center">
                          <span className="mx-1">
                            <div class="parentUI">
                              <div class="childUI child-1UI">
                                <button class="buttonUI btn-2UI align-center">
                                  <FaMixcloud color="#52aad8" />{" "}
                                </button>
                              </div>
                            </div>
                          </span>
                          <span className="ms-1 text-body">Mixcloud Link</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                  {companyData?.socialmedia?.[0]?.twitch ? (
                    <div className="my-1">
                      <Link to={companyData?.socialmedia?.[0]?.twitch} target="_blank">
                        <div className="d-flex align-center">
                          <span className="mx-1">
                            <div class="parentUI">
                              <div class="childUI child-1UI">
                                <button class="buttonUI btn-2UI align-center">
                                  <FaTwitch color="#6441a4" />{" "}
                                </button>
                              </div>
                            </div>
                          </span>
                          <span className="ms-1 text-body">Twitch Link</span>
                        </div>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </ModalBody>
                <ModalFooter className="bg-light">
                  <span className="sub-text">
                    provided by{" "}
                    <span className="text-primary" onClick={() => navigate(`/`)}>
                      {dj?.[activeVariable]?.section8?.map((item) => item?.alt4)}
                    </span>
                  </span>
                </ModalFooter>
              </Modal>
            </div>
            <div className="d-md-block d-none">
              <div className="d-flex flex-row h-50">
                {companyData?.socialmedia?.[0]?.website ? (
                  <span className="mx-1" id="websiteId">
                    <Link to={companyData?.socialmedia?.[0]?.website} target="_blank">
                      <div class="parentUI">
                        <div class="childUI child-1UI">
                          <button class="buttonUI btn-2UI align-center">
                            <Icon name="monitor" style={{ color: "#405DE6" }}></Icon>
                          </button>
                        </div>
                      </div>
                    </Link>
                    <TooltipComponent id={"websiteId"} text="Website" direction="bottom" />
                  </span>
                ) : (
                  ""
                )}
                {companyData?.socialmedia?.[0]?.facebook ? (
                  <span className="mx-1" id="facebookId">
                    <Link to={companyData?.socialmedia?.[0]?.facebook} target="_blank">
                      <div class="parentUI">
                        <div class="childUI child-1UI">
                          <button class="buttonUI btn-2UI align-center">
                            <Icon name="facebook-fill" style={{ color: "#316FF6" }}></Icon>
                          </button>
                        </div>
                      </div>
                      <TooltipComponent id={"facebookId"} text="Facebook" direction="bottom" />
                    </Link>
                  </span>
                ) : (
                  ""
                )}
                {companyData?.socialmedia?.[0]?.instagram ? (
                  <span className="mx-1" id="instagramId">
                    <Link to={companyData?.socialmedia?.[0]?.instagram} target="_blank">
                      <div class="parentUI">
                        <div class="childUI child-1UI">
                          <button class="buttonUI btn-2UI align-center">
                            <Icon name="instagram" style={{ color: "#E1306C" }}></Icon>
                          </button>
                        </div>
                      </div>
                    </Link>
                    <TooltipComponent id={"instagramId"} text="Instagram" direction="bottom" />
                  </span>
                ) : (
                  ""
                )}
                {companyData?.socialmedia?.[0]?.tiktok ? (
                  <span className="mx-1" id="tiktokId">
                    <Link to={companyData?.socialmedia?.[0]?.tiktok} target="_blank">
                      <div class="parentUI">
                        <div class="childUI child-1UI">
                          <button class="buttonUI btn-2UI align-center">
                            <AiFillTikTok />
                          </button>
                        </div>
                      </div>
                    </Link>
                    <TooltipComponent id={"tiktokId"} text="Tiktok" direction="bottom" />
                  </span>
                ) : (
                  ""
                )}
                {companyData?.socialmedia?.[0]?.linkedin ? (
                  <span className="mx-1" id="linkedinId">
                    <Link to={companyData?.socialmedia?.[0]?.linkedin} target="_blank">
                      <div class="parentUI">
                        <div class="childUI child-1UI">
                          <button class="buttonUI btn-2UI align-center">
                            <Icon name="linkedin" style={{ color: "#0072b1" }}></Icon>
                          </button>
                        </div>
                      </div>
                    </Link>
                    <TooltipComponent id={"linkedinId"} text="Linkedin" direction="bottom" />
                  </span>
                ) : (
                  ""
                )}
                {companyData?.socialmedia?.[0]?.soundcloud ? (
                  <span className="mx-1" id="soundcloudId">
                    <Link to={companyData?.socialmedia?.[0]?.soundcloud} target="_blank">
                      <div class="parentUI">
                        <div class="childUI child-1UI">
                          <button class="buttonUI btn-2UI align-center">
                            <Icon name="soundcloud" style={{ color: "#ff5500" }}></Icon>
                          </button>
                        </div>
                      </div>
                    </Link>
                    <TooltipComponent id={"soundcloudId"} text="Soundcloud" direction="bottom" />
                  </span>
                ) : (
                  ""
                )}
                {companyData?.socialmedia?.[0]?.mixcloud ? (
                  <span className="mx-1" id="mixcloudId">
                    <Link to={companyData?.socialmedia?.[0]?.mixcloud} target="_blank">
                      <div class="parentUI">
                        <div class="childUI child-1UI">
                          <button class="buttonUI btn-2UI align-center">
                            <FaMixcloud color="#52aad8" />
                          </button>
                        </div>
                      </div>
                    </Link>
                    <TooltipComponent id={"mixcloudId"} text="Mixcloud" direction="bottom" />
                  </span>
                ) : (
                  ""
                )}
                {companyData?.socialmedia?.[0]?.twitch ? (
                  <span className="mx-1" id="TwitchId">
                    <Link to={companyData?.socialmedia?.[0]?.twitch} target="_blank">
                      <div class="parentUI">
                        <div class="childUI child-1UI">
                          <button class="buttonUI btn-2UI align-center">
                            <FaTwitch color="#6441a4" />
                          </button>
                        </div>
                      </div>
                    </Link>
                    <TooltipComponent id={"TwitchId"} text="Twitch" direction="bottom" />
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </Card>
      </Block>
      <div className="responsive image d-flex border-1 pb-0 overflow-auto" style={{ height: "620px" }}>
        <Fancybox
          options={{
            Carousel: {
              infinite: false,
            },
          }}
        >
          {Object.keys(companyData?.images || {}).length > 0 && companyData?.images ? (
            Object.keys(companyData.images).map((category, categoryIdx) =>
              companyData.images[category].length > 0 ? (
                companyData.images[category].map((image, idx) => (
                  <div className="col-4  justify-center mt-0" key={idx} style={{ padding: "1px 1px" }}>
                    <a
                      data-fancybox="gallery"
                      href={image.url ? image.url : activeVariable == "CRM" ? placeHolderImg : placeHolderImg1}
                      className="justify-center w-100"
                    >
                      <img
                        alt={dj?.[activeVariable]?.section8?.map((item) => item?.alt2)}
                        src={image.url ? image.url : activeVariable == "CRM" ? placeHolderImg : placeHolderImg1}
                        className="object-fit-cover h-100 w-100"
                      />
                    </a>
                  </div>
                ))
              ) : (
                <div className="align-center justify-center" style={{ height: "50vh" }}>
                  <Card className="p-2">
                    <div className="justify-center  ">
                      <img
                        src={activeVariable == "CRM" ? placeHolderImg : placeHolderImg1}
                        alt="No-Result"
                        height="200px"
                      />
                    </div>
                  </Card>
                </div>
              )
            )
          ) : (
            <div className="align-center justify-center" style={{ height: "50vh" }}>
              <Card className="p-2">
                <div className="justify-center  ">
                  <img
                    src={activeVariable == "CRM" ? placeHolderImg : placeHolderImg1}
                    alt="No-Result"
                    height="200px"
                  />
                </div>
              </Card>
            </div>
          )}
        </Fancybox>
      </div>
    </>
  );
};

export default ImageCarousel;
