import React, { useState, useEffect } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Link } from "react-router-dom";
import { Icon } from "../../../../components/Component";
import { useThemeUpdate } from "../../../provider/Theme";
import Cookies from "js-cookie";
import data from "../../../../jsonfiles/Header.json";
import { getActiveEnvironmentVariable } from "../../../../utils/Utils";

const AppDropdown = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const [headerLinks, setHeaderLinks] = useState([]);
  const authTokenChk = Cookies.get("authToken");

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen(!open);
  };

  const currentUrl = window.location.pathname || null;

  function setCookieVariable() {
    const expires = new Date(Date.now() + 24 * 60 * 60 * 1000);
    Cookies.set("cookieVal", "clientRole3", {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires,
    });
  }
  const activeVariable = getActiveEnvironmentVariable();

  useEffect(() => {
    const activeEnv = getActiveEnvironmentVariable();
    if (activeEnv && data.links[activeEnv]) {
      setHeaderLinks(data.links[activeEnv]);
    } else {
      console.warn("No matching header links found for the active environment.");
    }
  }, []);


  return (
    <Dropdown isOpen={open} toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#dropdown"
        onClick={(ev) => ev.preventDefault()}
        className="dropdown-toggle nk-quick-nav-icon"
      >
        <div className="icon-status icon-status-na">
          <Icon name="menu-circled" />
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg">
        <div className="dropdown-body">
          <ul className="list-apps">
            {headerLinks.map((link, index) => (
              <li key={index}>
                <Link
                  to={link.name === "Blog" ? process.env.REACT_APP_BLOG_URL : link.path}
                  onClick={toggle}
                  className={currentUrl === link.path ? "text-primary nav-link" : "infoSection"}
                  // target={link.name === "Blog" ? "_blank" : "_self"}
                >
                  <span className="list-apps-media">
                    <Icon name={link.iconname} />
                  </span>
                  <span className={currentUrl === link.path ? "text-primary nav-link" : "infoSection"}>
                    {link.name}
                  </span>
                </Link>
              </li>
            ))}
            {!authTokenChk && (
              <li className="nav-item">
                <Link
                  to={`${process.env.REACT_APP_ACCOUNT_URL}login`}
                  className="nav-link"
                  onClick={setCookieVariable}
                >
                  <button className="btn btn-primary">Sign In</button>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default AppDropdown;
