import React, { useState, useEffect, useRef } from "react";
import { Button, Card, Col, Row, Spinner } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import Select, { components } from "react-select";
import { Links } from "../../jsonfiles/Links";
import contact from "../../jsonfiles/Contact.json";
import "../../css/style.css";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import JoinUs from "./JoinUs";

// Hardcoded or Imported Images
import HeroImage from "../images/hyattsville-3.jpg"; // Update with your actual image path
import contactimg from "../images/projectplanning.jpg"; // Update with your actual image path

export const Required = () => {
  return <span style={{ color: "red" }}> *</span>;
};

const activeVariable = getActiveEnvironmentVariable();

const Option = (props) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" />
    {props.data.label}
  </components.Option>
);

const ConstructionContactUs = () => {
  const HeroSectionImage1 = HeroImage;
  const HeroSectionImagealt = "Hero Image Alt Text";

  const [loading, setLoading] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    message: "",
    country_id: "",
  });
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const recaptchaRef = useRef();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`)
      .then((response) => response.json())
      .then((data) => {
        const countriesData = data.data.countries.map((country) => ({
          value: country.shortname,
          label: "+" + country.phonecode,
          id: country.id,
          icon: `https://flagsapi.com/${country.shortname}/shiny/64.png`,
        }));
        setCountries(countriesData);
        setSelectedCountry(countriesData?.[0]);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);

  const handleChange = (value) => {
    setSelectedCountry(value);
    setFormData({ ...formData, country_id: value?.id });
  };

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      contact: "",
      message: "",
      country_id: "",
    });
    reset({});
  };

  const [messageVisible, setMessageVisible] = useState(false);

  const onFormSubmit = async (form) => {
    if (!captchaResponse) {
      toast.error("Please resolve the captcha");
      return false;
    }
    const { name, email, country_id, contact, message } = form;
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("country", country_id);
      formData.append("contact", contact);
      formData.append("message", message);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/add-contactUs`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        toast.error("Failed to submit form");
        throw new Error("Failed to submit form");
      }
      setLoading(false);
      setMessageVisible(true);
      resetForm();
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setCaptchaResponse(null);
    } catch (error) {
      console.log(error);
      toast.error("Failed to submit form");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessageVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, [messageVisible]);

  return (
    <div className="contact-section">
      <div
        className="d-flex flex-column justify-content-center align-center h-500px"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${HeroSectionImage1})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100%",
          width: "100%",
          borderRadius: "10px",
        }}
      >
        <h2
          className="ff-alt fw-semibold text-capitalize text-center text-white"
          style={{
            fontSize: "calc(1.5rem + 2vw)", // Dynamically adjust font size
            lineHeight: "1.2",
            margin: "20px 0",
          }}
        >
          Strong Foundations for a Better Tomorrow!
        </h2>

        <h5 className="ff-alt fw-semibold text-capitalize text-center text-white fs-1">
          {contact?.[activeVariable]?.section1?.map((item) => item?.title1)}
        </h5>
      </div>
      <div className="d-flex flex-column align-center justify-center w-100 my-4" id="construction-contact">
        <Card className="px-5 py-3">
          <h2 className="fs-2 text-center">{contact?.[activeVariable]?.section1?.map((item) => item?.title2)}</h2>
          <p className="my-1">{contact?.[activeVariable]?.section1?.map((item) => item?.text)}</p>
        </Card>
        <Row className="g-2 m-0 w-100 pb-4">
          <Col md={12} lg={6} className="p-0 pe-lg-2">
            <div className="h-80 d-flex align-items-stretch">
              <div className="d-flex rounded-3 h-100 w-100">
                <img
                  src={contactimg} // Using imported image here
                  alt={HeroSectionImagealt}
                  style={{
                    borderTopRightRadius: "20px",
                    borderBottomRightRadius: "20px",
                    objectFit: "cover",
                  }}
                  className="d-none d-lg-block w-100 h-100"
                />
                <img
                  src={HeroSectionImage1}
                  alt="HeroSectionImage"
                  className="d-lg-none w-100"
                  style={{ height: "80%" }}
                />
              </div>
            </div>
          </Col>
          <Col md={12} lg={6} className="contact-form-col">
            <div className="h-90 d-flex align-items-stretch mt-1">
              <form
                noValidate
                onSubmit={handleSubmit(onFormSubmit)}
                className="w-100 h-100 d-flex flex-column justify-content-center"
                style={{
                  border: "1px solid #ccc", // Light gray border
                  borderRadius: "10px", // Rounded corners
                  padding: "20px", // Space inside the border
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Soft shadow
                }}
              >
                <Row className="g-0">
                  <Col lg={6}>
                    <div className="form-group w-75 mb-2">
                      <label htmlFor="name">
                        Full Name <Required />
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        placeholder="Enter Full Name"
                        {...register("name", {
                          required: "This field is required",
                          pattern: {
                            value: /^[A-Za-z\s]+$/,
                            message: "Only alphabets and spaces are allowed",
                          },
                          minLength: {
                            value: 3,
                            message: "Please enter at least 3 characters",
                          },
                        })}
                        value={formData.name}
                        onChange={(e) => {
                          setFormData({ ...formData, name: e.target.value });
                          clearErrors(e.target.name);
                        }}
                        maxLength={30}
                      />
                      {errors.name && <span className="text-danger">{errors.name.message}</span>}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="form-group w-75 mb-2">
                      <label htmlFor="email">
                        Email Address <Required />
                      </label>
                      <input
                        className="form-control"
                        type="email"
                        id="email"
                        placeholder="Enter Email"
                        {...register("email", {
                          required: "This field is required",
                          pattern: {
                            value: /^[^@]+@[^@]+\.[^@]+$/,
                            message: "Please enter a valid email address",
                          },
                        })}
                        value={formData.email}
                        onChange={(e) => {
                          setFormData({ ...formData, email: e.target.value });
                          clearErrors(e.target.name);
                        }}
                      />
                      {errors.email && <span className="text-danger">{errors.email.message}</span>}
                    </div>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col lg={6}>
                    <div className="form-group w-75 mb-2">
                      <label htmlFor="contact">
                        Contact Number <Required />
                      </label>
                      <div className="d-flex">
                        <Select
                          value={selectedCountry}
                          onChange={handleChange}
                          options={countries}
                          className="country-select"
                          components={{ Option }}
                        />
                        <input
                          className="form-control"
                          type="text"
                          id="contact"
                          placeholder="Enter Contact Number"
                          {...register("contact", {
                            required: "This field is required",
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Please enter a valid contact number",
                            },
                            minLength: {
                              value: 10,
                              message: "Please enter a valid contact number",
                            },
                          })}
                          value={formData.contact}
                          onChange={(e) => {
                            setFormData({ ...formData, contact: e.target.value });
                            clearErrors(e.target.name);
                          }}
                        />
                        {errors.contact && <span className="text-danger">{errors.contact.message}</span>}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="g-0">
                  <Col lg={12}>
                    <div className="form-group w-100 mb-2">
                      <label htmlFor="message">
                        How can we help you? <Required />
                      </label>
                      <textarea
                        className="form-control"
                        id="message"
                        placeholder="Please share your message or request"
                        {...register("message", {
                          required: "This field is required",
                          minLength: {
                            value: 10,
                            message: "Please enter at least 10 characters",
                          },
                        })}
                        value={formData.message}
                        onChange={(e) => {
                          setFormData({ ...formData, message: e.target.value });
                          clearErrors(e.target.name);
                        }}
                      />
                      {errors.message && <span className="text-danger">{errors.message.message}</span>}
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-center my-3">
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_GOOGLE_CAPTCHA_SITE_KEY}
                    onChange={(value) => setCaptchaResponse(value)}
                    ref={recaptchaRef}
                  />
                </div>
                <Button type="submit" color="primary" block disabled={loading}>
                  {loading ? <Spinner size="sm" /> : "Submit"}
                </Button>
              </form>
            </div>
          </Col>
        </Row>
      </div>
      <JoinUs/>
    </div>
  
  );
};

export default ConstructionContactUs;
