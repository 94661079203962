import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

// Error Pages
import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

// File Manager Pages
import FileManager from "../pages/Home Page/Homepage";
import FileManagerFiles from "../pages/Home Page/Homepage";
import FileManagerShared from "../pages/Home Page/Homepage";
import FileManagerStarred from "../pages/Home Page/Homepage";
import FileManagerRecovery from "../pages/Home Page/Homepage";
import FileManagerSettings from "../pages/Home Page/Homepage";

// Layouts
import LayoutApp from "../layout/Index-app";
import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";

// User Profile Pages
import UserProfileRegular from "../pages/prebuilt/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/prebuilt/user-manage/UserProfileSetting";
import UserProfileNotification from "../pages/prebuilt/user-manage/UserProfileNotification";

// General Pages
import Homepage from "../pages/Home Page/Homepage";
import DjProfiles from "../pages/DjProfiles/ProfilesDj";
import SelectDJ from "../pages/Individual DJ Pages/SelectDJ";
import DjTimeLine from "../pages/DJ Section/DjTimeLine";
import DJBookinEvent from "../pages/Individual DJ Pages/DJBookinEvent";
import AboutSection from "../pages/headers/about/AboutSection";
import Contact from "../pages/headers/Contact";
import ClientTimeLine from "../pages/DJ Section/ClientTimeLine";
import BusinessPortal from "../pages/headers/Business Portal/BusinessPortal";
import TermsCondition from "../pages/footer/TermsCondition";
import PrivacyPolicy from "../pages/footer/PrivacyPolicy";
import RefundCancellation from "../pages/footer/RefundCancellation";

// Construction Components
import HomeConstruction from "../construction_website/components/HomeConstruction";
import DetailsPage from "../construction_website/components/ViewDetails";
import ConstructionContactUs from "../construction_website/components/ContactUs";
// import ConstructionServices from "../construction_website/components/ConstructionServices";
import Construction_SignIn from "../construction_website/components/Construction_SignIn";
import AboutUsSection from "../construction_website/components/AboutUsSection";
import Blog from "../construction_website/components/Blog";
import ConstructionBusinessPortal from "../construction_website/components/ConstructionBusinessPortal";
import ReadMorePage from "../construction_website/components/ReadMorePage";

// Photography Components
import HomePgPhotography from "../Photography/HomePage/HomePgPhotography";
import PhotographyAbout from "../Photography/AboutpgPhoto/PhotographyAbout";
import StudioPhoto from "../Photography/Studio/StudioPhoto";
import BlogHeader from "../Photography/BlogPhoto/BlogHeader";
import PostPhotoBlog from "../Photography/BlogPhoto/PostPhotoBlog";
import ContactHeader from "../Photography/ContactpgPhoto/ContactHeader";
import PortalHeader from "../Photography/BusinessPhoto/PortalHeader";
import Service1 from "../Photography/AboutpgPhoto/Service1";

// Default Home Page
import HomePage from "../pages/Home Page/Homepage";
import Profilephoto from "../Photography/pofilephoto";

const Pages = () => {
  const location = useLocation();

  const isPhotographyEnabled = process.env.REACT_APP_PHOTOGRAPHY === "true";
  const isCRMEnabled = process.env.REACT_APP_CRM === "true";
  const isConstructionEnabled = process.env.REACT_APP_CONSTRUCTION === "true";

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      {/* Main Layout */}
      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<Layout app={{ icon: "dashboard", theme: "bg-purple-dim", text: "Dashboard" }} />}
      >
        {/* Photography Routes */}
        {isPhotographyEnabled && (
          <>
            <Route index element={<HomePgPhotography />} />
            <Route path="homepgphoto" element={<HomePgPhotography />} />
            <Route path="about" element={<PhotographyAbout />} />
            <Route path="profiles/:pageNumber" element={<DjProfiles />} />
            <Route path="profiles/specific/:id" element={<SelectDJ />} />
            <Route path="profiles/specific/:id/dj-bookevent" element={<DJBookinEvent />} />
            <Route path="studio" element={<StudioPhoto />} />
            <Route path="blogphoto" element={<BlogHeader />} />
            <Route path="contact" element={<ContactHeader />} />
            <Route path="business-portal" element={<PortalHeader />} />
            <Route path="post" element={<PostPhotoBlog />} />
            <Route path="service/photography" element={<Service1 />} />
            <Route path="terms-and-conditions" element={<TermsCondition />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="refund-and-cancellation" element={<RefundCancellation />} />
          </>
        )}
        {console.log("isConstructionEnabled:", isConstructionEnabled)}

        {/* Construction Routes */}
        {isConstructionEnabled && (
          <>
            <Route index element={<HomeConstruction />} />
            <Route path="/Home" element={<HomeConstruction />}></Route>
            <Route path="/about" element={<AboutUsSection />}></Route>
            <Route path="/details-page/:id" element={<DetailsPage />} />
            {/* <Route path="/ConstructionServices" element={<ConstructionServices/>} /> */}
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<ConstructionContactUs/>} />
            <Route path="/buisness-portal" element={<ConstructionBusinessPortal />} />
            <Route path="profiles/specific/:id/dj-bookevent" element={<DJBookinEvent />} />
            <Route path="/signin" element={<Construction_SignIn />} />
            <Route path="/read-more" element={<ReadMorePage />} />
            {/* <Route path="/profile" element={<CompanysProfile/>} /> */}
            <Route path="profiles/:pageNumber" element={<DjProfiles />} />
            <Route path="profiles/specific/:id" element={<SelectDJ />} />
            <Route path="terms-and-conditions" element={<TermsCondition />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="refund-and-cancellation" element={<RefundCancellation />} />
          </>
        )}

        {/* CRM Routes */}
        {isCRMEnabled && (
          <>
            <Route index element={<HomePage />} />
            <Route path="about" element={<AboutSection />} />
            <Route path="section" element={<DjTimeLine />} />
            <Route path="contact" element={<Contact />} />
            <Route path="business-portal" element={<BusinessPortal />} />
            <Route path="profiles/:pageNumber" element={<DjProfiles />} />
            <Route path="profiles/specific/:id" element={<SelectDJ />} />
            <Route path="client-section" element={<ClientTimeLine />} />
            <Route path="terms-and-conditions" element={<TermsCondition />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="refund-and-cancellation" element={<RefundCancellation />} />
            <Route path="profiles/specific/:id/dj-bookevent" element={<DJBookinEvent />} />
            <Route path="user-profile-notification" element={<UserProfileNotification />} />
            <Route path="user-profile-regular" element={<UserProfileRegular />} />
            <Route path="user-profile-setting" element={<UserProfileSetting />} />
          </>
        )}
      </Route>

      {/* File Manager Routes */}
      <Route path={`${process.env.PUBLIC_URL}`}>
        <Route element={<Layout app={{ icon: "folder", theme: "bg-orange-dim", text: "File Manager" }} />}>
          <Route path="app-file-manager">
            <Route index element={<FileManager />} />
            <Route path="files" element={<FileManagerFiles />} />
            <Route path="starred" element={<FileManagerStarred />} />
            <Route path="shared" element={<FileManagerShared />} />
            <Route path="recovery" element={<FileManagerRecovery />} />
            <Route path="settings" element={<FileManagerSettings />} />
          </Route>
        </Route>
      </Route>

      {/* Error Pages */}
      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />} />
          <Route path="404-classic" element={<Error404Classic />} />
          <Route path="504-modern" element={<Error504Modern />} />
          <Route path="504-classic" element={<Error504Classic />} />
        </Route>
        <Route path="*" element={<Error404Modern />} />
      </Route>
    </Routes>
  );
};

export default Pages;
