import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import { useNavigate } from "react-router";
import { ShimmerContentBlock } from "react-shimmer-effects";
import { Link } from "react-scroll";
import CookieSiteSettings from "../../utils/cookieUtils";
import dj from "../../jsonfiles/Dj.json";
import { Links } from "../../jsonfiles/Links";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import { TooltipComponent } from "../../components/Component";
import Swal from "sweetalert2";
import NoFounds2 from "../../images/Login-No-Background.png";
import NoFounds from "../../images/No Result.png";
import NoFounds1 from "../../images/placeholderimageforallproject.jpg";
import Cookies from "js-cookie";
import book from "../../jsonfiles/Booking.json";

const ListView = ({ profiles, companiesListLoading }) => {
  const activeVariable = getActiveEnvironmentVariable();


  const PlaceHolderImage = Links?.[activeVariable]?.Home?.[0]?.url4;

  const navigate = useNavigate();
  const [currency, setCurrency] = useState("sd");
  useEffect(() => {
    const getCurrency = async () => {
      const currencyValue = CookieSiteSettings();
      setCurrency(currencyValue?.other?.currency);
    };
    getCurrency();
  }, []);
  const chkDJcompany = Cookies.get("role");
  const cookieValue = Cookies.get("authToken");
console.log("chkDJcompany",chkDJcompany)
  // const handlechat =(e)=>{

  //   if (!cookieValue) {
  //     Swal.fire({
  //        title: "You are not Sign-In",
  //         text: book?.[activeVariable]?.section10?.map((item) => item?.text1),
  //   imageUrl: activeVariable === "CRM" ? NoFounds : NoFounds1,
  //     imageWidth: 200,
  // imageHeight: 200,
  //      imageAlt: "Sign-In Image",
  //         confirmButtonText: "Sign-In",
  //           showCancelButton: true,
  //          customClass: {
  //            confirmButton: "btn btn-primary",
  //             cancelButton: "btn btn-danger",
  //           },
  //          buttonsStyling: false,
  //   })

  // }

  // }

  // const handlechat = (e) => {
  //   e.stopPropagation();

  //   if (!cookieValue) {
  //     Swal.fire({
  //       title: "You are not Sign-In",
  //       text: book?.[activeVariable]?.section10?.map((item) => item?.text1),
  //       imageUrl: activeVariable === "CRM" ? NoFounds : NoFounds1,
  //       imageWidth: 200,
  //       imageHeight: 200,
  //       imageAlt: "Sign-In Image",
  //       confirmButtonText: "Sign-In",
  //       showCancelButton: true,
  //       customClass: {
  //         confirmButton: "btn btn-primary",
  //         cancelButton: "btn btn-danger",
  //       },
  //       buttonsStyling: false,
  //     });
  //   } else {
  //     navigate("https://clients.ipartydjcrm.com/chat");
  //   }
  // };

  const handlechat = (e) => {
    e.stopPropagation();
  
    if (!cookieValue) {
      Swal.fire({
        title: "You are not Sign-In",
        text:  book?.[activeVariable]?.section10?.map((item) => item?.text1),
        imageUrl: activeVariable === "CRM" ? NoFounds2 : NoFounds1,
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: "Sign-In Image",
        confirmButtonText: "Sign-In",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          const now = new Date();
          const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 1-day expiration for the cookie
          Cookies.set("cookieVal", "clientRole3", {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          Cookies.set("roleId", `${process.env.REACT_APP_PUBLIC_URL}profiles/1`, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });
          window.location = process.env.REACT_APP_ACCOUNT_URL;
        }
      });
    }  else {
      // If signed in, navigate to the chat
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 1 day expiration
  
      Cookies.set("chatInitiated", "true", {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
  
     
      // window.open(`${process.env.REACT_APP_CLIENT_URL}chat`, "_blank");
      if (chkDJcompany === "2") {
        window.open(`${process.env.REACT_APP_DASHBOARD_URL}chat`);
      } else if (chkDJcompany === "3") {
        window.open(`${process.env.REACT_APP_CLIENT_URL}chat`);
      }
    }
  };
  

  return (
    <div className="mb-3 overflow-auto" style={{ height: "830px", scrollbarWidth: "none" }}>
      {companiesListLoading ? (
        <div className="d-flex flex-column justify-center align-center">
          {Array.from({ length: 5 }).map((_, index) => (
            <ShimmerContentBlock title text cta thumbnailWidth={100} thumbnailHeight={100} />
          ))}
        </div>
      ) : (
        <div>
          {profiles.map((profile) => (
            <Row
              style={{ cursor: "pointer" }}
              key={profile?.id}
              className="my-2 border g-0 rounded-start-5 shawdow leaflet-bar"
              onClick={() => navigate(`/profiles/specific/${profile?.id}`)}
            >
              <Col lg={4} sm={6} xs={6} className="px-0">
                <div className="rounded-start-5">
                  <img
                    src={profile?.profile_image_path || PlaceHolderImage}
                    alt={profile?.company_name || "----"}
                    className="img-fluid object-fit-contain rounded-start-5"
                    style={{ height: "300px", width: "100%" }}
                  />
                </div>
              </Col>
              <Col lg={8} sm={6} xs={6} className="d-flex flex-column justify-content-center px-0">
                <div className="px-3">
                  <h5 className="fw-bold  mb-0 mb-md-2 fs-2 d-md-block d-none justify-content-between align-items-center">
                  <div className="profile-chat-container">
  <span>{profile?.company_name || "----"}</span>
  <div className="custom-chat-button" onClick={(e) => handlechat(e)}>
    <button id="chat-trigger" className="chat-btn">
      <i className="bi bi-chat-dots"></i>
    </button>
  </div>
</div>

                  </h5>

                  <h5 className="fw-bold mb-1 fs-6 d-md-none">
                    {profile?.company_name?.length > 17 ? (
                      <>
                       {/* <div className="profile-chat-container"> */}
                        {profile?.company_name?.substring(0, 10) }
                        <span className=" fw-bold">...</span>

                        
                        <div className="profile-chat-container">
                       
                        <TooltipComponent id="pkgTitle" text={profile?.company_name} direction="bottom" />
                      <div className="custom-chat-button" onClick={(e) => handlechat(e)}>
                        <button id="chat-trigger" className="chat-btn">
                          <i className="bi bi-chat-dots"></i>
                        </button>
                      </div>
                    </div>
                      </>
                    ) : (
                      // profile?.company_name || "----"
                      <div className="profile-chat-container">
                      <span>{profile?.company_name || "----"}</span>
                      <div className="custom-chat-button" onClick={(e) => handlechat(e)}>
                        <button id="chat-trigger" className="chat-btn">
                          <i className="bi bi-chat-dots"></i>
                        </button>
                      </div>
                    </div>
                    )}


                      {/* <div className="custom-chat-button" onClick={(e) => handlechat(e)}>
                        <button id="chat-trigger" className="chat-btn">
                          <i className="bi bi-chat-dots"></i>
                        </button>
                      </div> */}
                  
                  </h5>
                  <p className="text-muted fs-3 mb-0 mb-md-2 fw-medium d-md-block d-none">
                    {profile?.city || "----"},{profile?.state || "----"}
                  </p>
                  <p className="text-muted fs-7 mb-1 fw-medium d-md-none" style={{fontSize:"1rem"}}>
                    {profile?.city || "----"},{profile?.state || "----"}
                  </p>

                  <p className="fs-5 text-secondary d-md-block d-none" style={{ height: "90px", overflow: "hidden" }}>
                    {profile?.uniqueness && profile?.uniqueness.length > 230 ? (
                      <>
                        {profile?.uniqueness.substring(0, 230)}
                        <span className="text-primary fw-bold">...</span>
                      </>
                    ) : (
                      profile?.uniqueness || "----"
                    )}
                  </p>
                  <p className="fs-7 text-secondary d-md-none mb-2" style={{ height: "40px", overflow: "hidden" }}>
                    {profile?.uniqueness && profile?.uniqueness.length > 60 ? (
                      <>
                        {profile?.uniqueness.substring(0, 60)}
                        <span className="text-primary fw-bold">...</span>
                      </>
                    ) : (
                      profile?.uniqueness || "----"
                    )}
                  </p>
                  <div className="row mx-1 w-100">
                    <div className="d-flex align-center col-md-6 col-12 p-0 py-1">
                      <p className="fs-5 d-md-block d-none m-0">
                        Starting from:{" "}
                        {new Intl.NumberFormat("en-IN", { currency: "INR" }).format(
                          profile?.max_packages?.[0]?.min_package_price || 0
                        )}{" "}
                        {currency === "$" ? "USD" : "INR"}{" "}
                      </p>
                      <p className="fs-7 d-md-none">
                        Starting from:{" "}
                        {new Intl.NumberFormat("en-IN", { currency: "INR" }).format(
                          profile?.max_packages?.[0]?.min_package_price || 0
                        )}{" "}
                        {currency === "$" ? "USD" : "INR"}{" "}
                      </p>
                    </div>

                    <div className="col-md-6 col-12 p-0 justify-end align-center">
                      <Button
                        color="primary"
                        className="p-0 h-75 px-3 pt-1 pb-3 d-md-block d-none"
                        onClick={() => navigate(`/profiles/specific/${profile?.id}`)}
                      >
                        {dj?.[activeVariable]?.section5?.map((item) => item?.title1)}
                      </Button>
                      <Button
                        color="primary"
                        className="h-75 d-md-none me-md-0 me-4"
                        style={{ fontSize: "9px" }}
                        onClick={() => navigate(`/profiles/specific/${profile?.id}`)}
                      >
                        {dj?.[activeVariable]?.section5?.map((item) => item?.title1)}
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      )}
    </div>
  );
};

export default ListView;
