import React from "react";
import { Helmet } from "react-helmet";
import { getActiveEnvironmentVariable } from "../../utils/Utils";


const Head = ({ ...props }) => {
  const activeVariable = getActiveEnvironmentVariable();
  return (
    <Helmet>
      {/* <title>{props.title ? props.title + " | " : null} DashLite React Admin Template</title> */}
      <title>{activeVariable ==="CRM" && " I Party Dj crm" }</title>
      <title>{activeVariable ==="photography" && "CLICKMYPHOTO" }</title>
      <title>{activeVariable ==="construction" && "Centera Space" }</title>

    </Helmet>
  );
};
export default Head;
