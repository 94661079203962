import { getCookie } from "../../utils/Utils";

// Get API to fetch Client details =================================
export const getAllCompanies = async (values) => {
  const formData = new FormData();

  if (values.id) {
    formData.append("company_id", values.id);
  }

  if (values && values.type) {
    formData.append("type", values.type);
  }

  if (values && values.offset) {
    formData.append("offset", values.offset);
  }

  if (values.category) {
    formData.append("category", values.category);
  }

  if (values.city) {
    formData.append("city", values.city);
  }

  if (values.selectedServices) {
    formData.append("services", values.selectedServices);
  }
  if (values.selectedGeners) {
    formData.append("genres", values.selectedGeners);
  }
  if (values.budget) {
    formData.append("package", values.budget);
  }
  if (values.search) {
    formData.append("search", values.search);
  }
  if (values.sortValue) {
    formData.append("sort_package", values.sortValue);
  }

  const requestOptions = {
    method: "POST",
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-all-companies`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting client details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getAllCategories = async () => {
  const requestOptions = {
    method: "GET",
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-category`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting category details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getAllCities = async (values) => {
  const formdata = new FormData();
  formdata.append("limit", 10);
  if (values?.city) {
    formdata.append("search", values?.city);
  }
  const requestOptions = {
    method: "POST",
    body: formdata,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/cities`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting cities details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getAllServices = async () => {
  const requestOptions = {
    method: "GET",
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-services`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting services details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getAllGenre = async () => {
  const requestOptions = {
    method: "GET",
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-genres`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting genres details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getAllCount = async () => {
  const requestOptions = {
    method: "GET",
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/public/get-service-genres-count`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting count of services and genres");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getCompanyFeaturePerformance = async (values) => {
  const formData = new FormData();

  if (values && values.type) {
    formData.append("limit", values.type);
  }
  const requestOptions = {
    method: "Post",
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/public/get-company-feature-performance`,
    requestOptions
  );

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting category details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getAttire = async () => {
  const requestOptions = {
    method: "Post",
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-attire`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting attire details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getVenue = async () => {
  const requestOptions = {
    method: "Post",
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-venues`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting attire details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getPackages = async (values) => {
  const formData = new FormData();
  if (values.id) {
    formData.append("company_id", values.id);
  }
  const requestOptions = {
    method: "Post",
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-packages`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting package details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getReview = async (values) => {
  const formData = new FormData();
  if (values.id) {
    formData.append("company_id", values.id);
  }
  const requestOptions = {
    method: "Post",
    body: formData,
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-review`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting review details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getAvailableSlot = async (values) => {
  const formData = new FormData();

  if (values.id) {
    formData.append("company_id", values.id);
  }
  if (values.key) {
    formData.append("key", values.key);
  }
  if (values.date) {
    formData.append("date", values.date);
  }
  if (values.interval1) {
    formData.append("interval1", values.interval1);
  }

  const requestOptions = {
    method: "POST",
    body: formData,
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/public/get-available-event-time-slot`,
    requestOptions
  );
  if (!response.ok) {
    const errorInfo = await response.json();
    const error = new Error("An Error occurred while getting the event time slot");
    error.info = errorInfo;
    error.code = response.status;
    throw error;
  }

  const res = await response.json();
  return res;
};

// export const getAvailableSlot1 = async (values) => {
//   const formData = new FormData();

//   if (values.id) {
//     formData.append("id", values.id);
//   }
//   const requestOptions = {
//     method: "POST",
//     body: formData,
//   };
//   const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-event-time-slot`, requestOptions);



//   if (!response.ok) {
//     const errorInfo = await response.json();

//     const error = new Error("An Error occured while getting event time slots");
//     error.info = errorInfo;
//     error.code = response.status;

//     throw error;
//   }

//   const res = await response.json();

//   return res;
// };
export const getAvailableSlot1 = async (values) => {
  const formData = new FormData();
  if (values.id) {
    formData.append("company_id", values.id);
  }
  const requestOptions = {
    method: "Post",
    body: formData,
  };
  // const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-timeoff-slots`, requestOptions);
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-timeoff-slots`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occurred while getting event time slots");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};

export const getmusics = async (values) => {
  const formData = new FormData();
  formData.append("limit", "10");
  formData.append("search", values.music);
  const requestOptions = {
    method: "POST",
    body: formData,
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-musics`, requestOptions);
    if (!response.ok) {
      const errorInfo = await response.json();
      const error = new Error("An error occurred while fetching currency settings");
      error.info = errorInfo;
      error.code = response.status;
      throw error;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching musics settings:", error);
  }
};

export const getClientData = async () => {
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/account/get-only-profile-img`, requestOptions);
    if (!response.ok) {
      const errorInfo = await response.json();
      const error = new Error("An error occurred while fetching client data");
      error.info = errorInfo;
      error.code = response.status;
      throw error;
    }
    const blob = await response.blob();
    const imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  } catch (error) {
    console.error("Error fetching client data:", error);
  }
};

export const getCountryData = async () => {
  const requestOptions = {
    method: "GET",
  };

  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting services details");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }
  return response.json();
};

export const getAvailableSlot11 = async (values) => {
  const formData = new FormData();

  if (values.id) {
    formData.append("id", values.id);
  }
};
export const getsitesetting = async (values) => {
  const formData = new FormData();
  formData.append("module_id", "0");
  formData.append("support_email", "support_email");
  formData.append("support_phone", "support_phone");
  const requestOptions = {
    method: "POST",
    body: formData,
  };
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-site-settings`, requestOptions);
    if (!response.ok) {
      const errorInfo = await response.json();
      const error = new Error("An error occurred while fetching currency settings");
      error.info = errorInfo;
      error.code = response.status;
      throw error;
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching get settings:", error);
  }
};

export const getdj = async () => {
  const requestOptions = {
    method: "GET",
  };
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-dj-count-log`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting Country list");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();
  return res;
};

export const getpdf = async (values) => {
  const formData = new FormData();

  if (values.id) {
    formData.append("event_id", values.id);
  }
  
  const requestOptions = {
    method: "POST",
    headers: {
      authToken: getCookie("authToken"),
    },
    body: formData,
  };
  const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/event-info/get-event-info`, requestOptions);

  if (!response.ok) {
    const errorInfo = await response.json();

    const error = new Error("An Error occured while getting epdf");
    error.info = errorInfo;
    error.code = response.status;

    throw error;
  }

  const res = await response.json();

  return res;
};


export const getModelAndCards = async (values) => {
  const formData = new FormData();
  if (values.id) {
    formData.append("user_id", values.id);
  }
  const requestOptions = {
    method: "POST",
    body: formData,
    headers: {
      authToken: getCookie("authToken"),
    },
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/event-info/get-event-info`,
    requestOptions
  );

  if (!response.ok) {
    // Create a new Error object to handle errors
    const error = new Error("Failed to fetch event info");
    const errorInfo = await response.json();
    error.info = errorInfo;
    error.code = response.status;

    // Throw the custom error object
    throw error;
  }

  // Return the response if everything is fine
  return await response.json();
};
