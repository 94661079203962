import React, { useContext, useEffect, useRef, useState } from "react";
import { Block, BlockTitle } from "../../components/Component";
import { Button, Card, Col, Row, Spinner, Container } from "reactstrap";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
// import services2 from "../../images/contact_us2.jpg";
import "../../css/style.css";
import ReCAPTCHA from "react-google-recaptcha";
import { createcontext } from "../../layout/Index";
import { Links } from "../../jsonfiles/Links";
import Select, { components } from "react-select";
import { getActiveEnvironmentVariable } from "../../utils/Utils";
import "../CssPhoto/customstyle.css";
import homepg from "../photojsonfiles/HomePhoto.json";
import formimg from '../ImagesPhotography/contactformimg.jpg'

export const Required = () => {
  return (
    <>
      <span style={{ color: "red" }}> *</span>
    </>
  );
};
const activeVariable = "contactPhoto";
const section1 = homepg?.[activeVariable]?.section1 || [];

const Option = (props) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" />
    {props.data.label}
  </components.Option>
);
const ContactUs = () => {


  const HeroSectionImage1 = Links?.[activeVariable]?.Contact?.[0]?.url1;

  const services2 = Links?.[activeVariable]?.Contact?.[0]?.url2;

  const HeroSectionImagealt = Links?.[activeVariable]?.Contact?.[0]?.alt1;


  const [loading, setLoading] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState(null);
  const navigate = useNavigate();
  const blockNumbers = (val) => { };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
    country_id: "",
  });
  const { heroSection, setHeroSection } = useContext(createcontext);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 992);
  const recaptchaRef = useRef(null);
  // contact number operation
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  useEffect(() => {
    setFormData({ ...formData, country_id: countries?.[0]?.id });
  }, [countries]);
  const handleChange = (value) => {
    setSelectedCountry(value);
    setFormData({ ...formData, country_id: value?.id });
  };
  useEffect(() => {
    const searchQuery = window.location.hostname;
    fetch(`${process.env.REACT_APP_API_URL}api/v1/public/countries`)
      .then((response) => response.json())
      .then((data) => {
        const filteredIds = searchQuery.includes(".online")
          ? [101, 231] 
          : searchQuery === "localhost"
          ? [101, 231]
          : [];
        const countriesData = data.data.countries
        .filter((country) => filteredIds.includes(country.id))
        .map((country) => ({
          value: country.shortname,
          label: "+" + country.phonecode,
          id: country.id,
          icon: `https://flagsapi.com/${country.shortname}/shiny/64.png`,
        }));
        setCountries(countriesData);
        setSelectedCountry(countriesData[0]);
      })
      .catch((error) => console.error("Error fetching countries:", error));
  }, []);
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img src={props.data.icon} alt="s-logo" className="selected-logo" />
      {children}
    </components.SingleValue>
  );

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 992);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setHeroSection(true);
  }, []);
  useEffect(() => {
    reset(formData);
  }, [formData]);

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      phone_number: "",
      message: "",
      country_id: "",
    });
    reset({});
  };

  const onFormSubmit = async (form) => {
    if (!captchaResponse) {
      toast.error("Please resolve the captcha");
      return false;
    }
    const { name, email, country_id, phone_number, message } = form;
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("country", country_id);
      formData.append("phone_number", phone_number);
      formData.append("message", message);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/add-contactUs`, {
        method: "POST",
        body: formData,
      });
      if (!response.ok) {
        toast.error("Failed to submit form");
        throw new Error("Failed to submit form");
      }
      setLoading(false);
      toast.success("Submited Successfully");
      resetForm();
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      setCaptchaResponse(null);
    } catch (error) {
      console.log(error);
      toast.error("Failed to submit form");
    }
  };

  return (
<Block className="">
  <div className="block-container">
    <Container fluid className="custom-container">
      <Row className="text-center mt-4 mb-4">
        <Col md="12">
        <h2 className="fs-2 lead-text mb-3 text-center fw-bold" style={{ color: "#1e3d58",  }}>
            {section1[0]?.title}
          </h2>
          <p className="description-text">
            {section1[1]?.description}
          </p>
        </Col>
      </Row>

      <Row className="g-2 w-100 pb-4">
        <Col md={12} lg={6} className="p-0">
          <img
            // src={formimg}
            src="https://webneel.com/daily/sites/default/files/images/daily/02-2019/fisherman-creative-photography-ideas-aka-carson.jpg"
            alt="img"
            className="image-class"
          />
        </Col>
            <Col md={12} lg={6} className={` mx-0 p-0 ${!isLargeScreen ? "" : "contactfrm"}`}>
              <div className="mt-4" style={{ background: "linear-gradient(23deg, #d4d4d4 0%, #d5e6f0 35%, #eaf6fc 100%)" , borderRadius: "15px", padding: "10px 0px", }}>
                <form noValidate onSubmit={handleSubmit(onFormSubmit)} className="">
                  <Row className="g-0">
                    <Col lg={6} className="justify-center">
                      <div className="form-group w-75">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message " style={{ fontWeight: 'bold', color: "#526484", fontSize: '16px' }}>
                            Full Name <Required />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="name"
                            placeholder="Enter Full Name"
                            {...register("name", {
                              required: "This field is required",
                              pattern: {
                                value: /^[A-Za-z\s]+$/,
                                message: "Only alphabets and spaces are allowed",
                              },
                              minLength: {
                                value: 3,
                                message: "Please enter at least 3 characters",
                              },
                            })}
                            value={formData.name}
                            onChange={(e) => {
                              setFormData({ ...formData, name: e.target.value });
                              clearErrors(e.target.name);
                            }}
                            style={{
                             
                              boxShadow: '0 0 5px rgba(82, 100, 132, 0.6)', // Add shadow on focus
                            }}
                            onFocus={(e) => {
                              e.target.style.border = '2px solid #526484';
                              e.target.style.boxShadow = '0 0 5px rgba(82, 100, 132, 0.6)';
                            }}
                            onBlur={(e) => {
                              e.target.style.border = '1px solid #ccc';
                              e.target.style.boxShadow = 'none';
                            }}
                            maxLength={30}
                          />
                          {errors.name && <span className="invalid">{errors.name.message}</span>}
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className="justify-center">
                      <div className="form-group w-75">
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message" style={{ fontWeight: 'bold', color: "#526484", fontSize: '16px' }}>
                            Email <Required />
                          </label>
                          <input
                            className="form-control"
                            type="text"
                            id="email"
                            placeholder="Enter Email"
                            {...register("email", {
                              required: "This field is required",
                              pattern: {
                                value:
                                  // eslint-disable-next-line
                                  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                                message: "Enter a valid email address",
                              },
                              maxLength: { value: 255, message: "Email must not exceed 255 characters" },
                            })}
                            value={formData.email}
                            onChange={(e) => {
                              setFormData({ ...formData, email: e.target.value });
                              clearErrors(e.target.email);
                            }}
                            style={{
                              
                              boxShadow: '0 0 5px rgba(82, 100, 132, 0.6)', // Add shadow on focus
                            }}
                            onFocus={(e) => {
                              e.target.style.border = '2px solid #526484';
                              e.target.style.boxShadow = '0 0 5px rgba(82, 100, 132, 0.6)';
                            }}
                            onBlur={(e) => {
                              e.target.style.border = '1px solid #ccc';
                              e.target.style.boxShadow = 'none';
                            }}
                          />
                          {errors.email && <span className="invalid">{errors.email.message}</span>}
                        </div>
                      </div>
                    </Col>
                    {/* <Col lg={6} className="justify-center">
                      <div className="form-group w-75">
                        <div className="form-control-wrap my-2  d-lg-none">
                          <label htmlFor="event_message" style={{ fontWeight: 'bold', color: "#526484", fontSize: '16px' }}>
                            Contact Number <Required />
                          </label>
                          <div className="d-flex">
                            <Select
                              className="w-40"
                              value={selectedCountry}
                              options={countries}
                              onChange={handleChange}
                              styles={{
                                option: (provided, state) => ({
                                  ...provided,
                                  color: state.isSelected ? "white" : "black",
                                  backgroundColor: state.isSelected ? "#526484" : "white",
                                  display: "flex",
                                  alignItems: "center",
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  display: "flex",
                                  alignItems: "center",
                                }),
                              }}
                              components={{
                                Option,
                                SingleValue,
                              }}
                            />
                            <input
                              className="form-control "
                              type="number"
                              id="phone_number"
                              onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                              placeholder="Enter Contact Number"
                              {...register("phone_number", {
                                required: "This field is required",
                                maxLength: {
                                  value: 15,
                                  message: "You rich maximum contact number limit!",
                                },
                                minLength: {
                                  value: 10,
                                  message: "Please enter at least 10 digits",
                                },
                              })}
                              value={formData.phone_number}
                              onChange={(e) => {
                                if (e.target.value.length <= 15) {
                                  setFormData({ ...formData, phone_number: e.target.value });
                                } else {
                                  blockNumbers();
                                }
                              }}
                              style={{
                                border: '2px solid #526484',
                                boxShadow: '0 0 5px rgba(82, 100, 132, 0.6)', // Add shadow on focus
                              }}
                              onFocus={(e) => {
                                e.target.style.border = '2px solid #526484';
                                e.target.style.boxShadow = '0 0 5px rgba(82, 100, 132, 0.6)';
                              }}
                              onBlur={(e) => {
                                e.target.style.border = '1px solid #ccc';
                                e.target.style.boxShadow = 'none';
                              }}
                            />
                          </div>
                          {errors.phone_number && (
                            <span className="ff-italic text-danger" style={{ fontSize: "12px" }}>
                              {errors.phone_number.message}
                            </span>
                          )}
                        </div>
                        <div className="form-control-wrap my-2   d-none d-lg-block">
                          <label htmlFor="event_message" style={{ fontWeight: 'bold', color: "#526484", fontSize: '16px' }}>
                            Contact Number <Required />
                          </label>
                          <div className="d-flex">
                            <Select
                              className="w-75"
                              value={selectedCountry}
                              options={countries}
                              onChange={handleChange}
                              styles={{
                                option: (provided, state) => ({
                                  ...provided,
                                  color: state.isSelected ? "white" : "black",
                                  backgroundColor: state.isSelected ? "#526484" : "white",
                                  display: "flex",
                                  alignItems: "center",
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  display: "flex",
                                  alignItems: "center",
                                }),
                              }}
                              components={{
                                Option,
                                SingleValue,
                              }}
                            />
                            <input
                              className="form-control "
                              type="number"
                              id="phone_number"
                              onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                              placeholder="Enter Contact Number"
                              {...register("phone_number", {
                                required: "This field is required",
                                maxLength: {
                                  value: 15,
                                  message: "You rich maximum contact number limit!",
                                },
                                minLength: {
                                  value: 10,
                                  message: "Please enter at least 10 digits",
                                },
                              })}
                              value={formData.phone_number}
                              onChange={(e) => {
                                if (e.target.value.length <= 15) {
                                  setFormData({ ...formData, phone_number: e.target.value });
                                } else {
                                  blockNumbers();
                                }
                              }}
                              style={{
                                border: '2px solid #526484',
                                boxShadow: '0 0 5px rgba(82, 100, 132, 0.6)', // Add shadow on focus
                              }}
                              onFocus={(e) => {
                                e.target.style.border = '2px solid #526484';
                                e.target.style.boxShadow = '0 0 5px rgba(82, 100, 132, 0.6)';
                              }}
                              onBlur={(e) => {
                                e.target.style.border = '1px solid #ccc';
                                e.target.style.boxShadow = 'none';
                              }}
                            />
                          </div>
                          {errors.phone_number && (
                            <span className="ff-italic text-danger" style={{ fontSize: "12px" }}>
                              {errors.phone_number.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </Col> */}
                    <Col lg={6} className="justify-center">
                    <div className="form-group w-75">
                      <div className="form-control-wrap my-2 ">
                        <label htmlFor="event_message">
                          Contact Number <Required />
                        </label>
                        <div className="d-flex ">
                          <Select
                            className="w-65"
                            value={selectedCountry}
                            options={countries}
                            onChange={handleChange}
                            styles={{
                              option: (provided, state) => ({
                                ...provided,
                                color: state.isSelected ? "white" : "black",
                                backgroundColor: state.isSelected ? "#007bff" : "white",
                                display: "flex",
                                alignItems: "center",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                display: "flex",
                                alignItems: "center",
                              }),
                            }}
                            components={{
                              Option,
                              SingleValue,
                            }}
                          />
                          <input
                            
                            className="form-control "
                            type="number"
                            id="contact"
                            autoComplete="contact"
                            onKeyDown={(evt) => ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()}
                            placeholder="Enter Contact Number"
                            {...register("contact", {
                              required: "This field is required",
                              maxLength: {
                                value: 15,
                                message: "You rich maximum contact number limit!",
                              },
                              minLength: {
                                value: 10,
                                message: "Please enter at least 10 digits",
                              },
                            })}
                            style={{
                              
                              boxShadow: '0 0 5px rgba(82, 100, 132, 0.6)', // Add shadow on focus
                            }}
                            onFocus={(e) => {
                              e.target.style.border = '2px solid #526484';
                              e.target.style.boxShadow = '0 0 5px rgba(82, 100, 132, 0.6)';
                            }}
                            onBlur={(e) => {
                              e.target.style.border = '1px solid #ccc';
                              e.target.style.boxShadow = 'none';
                            }}
                            value={formData.contact}
                            onChange={(e) => {
                              if (e.target.value.length <= 15) {
                                setFormData({ ...formData, contact: e.target.value });
                              } else {
                                blockNumbers();
                              }
                            }}
                          />
                        </div>
                        {errors.contact && (
                          <span className="ff-italic text-danger" style={{ fontSize: "12px" }}>
                            {errors.contact.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </Col>
                    <Col lg={12} className="justify-center">
                      <div className={`form-group ${!isLargeScreen ? "w-75" : "w-90 px-2"}`}>
                        <div className="form-control-wrap my-2">
                          <label htmlFor="event_message" style={{ fontWeight: 'bold', color: "#526484", fontSize: '16px' }}>How can we help you?</label>
                          <textarea
                            className="form-control h-100px"
                            type="text"
                            id="event_message"
                            placeholder="Enter Event Message"
                            maxLength={500}
                            value={formData.message}
                            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                            style={{
                              
                              boxShadow: '0 0 5px rgba(82, 100, 132, 0.6)', // Add shadow on focus
                            }}
                            onFocus={(e) => {
                              e.target.style.border = '2px solid #526484';
                              e.target.style.boxShadow = '0 0 5px rgba(82, 100, 132, 0.6)';
                            }}
                            onBlur={(e) => {
                              e.target.style.border = '1px solid #ccc';
                              e.target.style.boxShadow = 'none';
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    {/* <Col lg={12} className="justify-center">
                      <div className="form-group w-90 px-2 justify-center">
                        <ReCAPTCHA
                          ref={recaptchaRef}
                          sitekey={process.env.REACT_APP_CAPTCHA_GOOGLE_CAPTCHA_SITE_KEY}
                          onChange={(response) => {
                            setCaptchaResponse(response);
                          }}
                        />
                      </div>
                    </Col>
                       */}
                       <Col lg={12} className="d-flex justify-content-center">
  <div className="form-group w-100 px-md-4 px-2 d-flex justify-content-center">
    <ReCAPTCHA
      ref={recaptchaRef}
      sitekey={process.env.REACT_APP_CAPTCHA_GOOGLE_CAPTCHA_SITE_KEY}
      onChange={(response) => {
        setCaptchaResponse(response);
      }}
      className="recaptcha-responsive"
    />
  </div>
</Col>


                  </Row>
                  <div className="justify-center my-2">
                    {loading ? (
                      <Button disabled color="tertiary" className="justify-center w-50">
                        <Spinner size="sm" />
                        <span>Loading...</span>
                      </Button>
                    ) : (
                      <Button color="tertiary" className="justify-center rounded w-50 p-2" type="submit" style={{ fontSize: '18px' }}>
                        Submit
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Block>
  );
};

export default ContactUs;
