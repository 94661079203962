import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import AppMain from "./global/AppMain";
import AppRoot from "./global/AppRoot";
import AppHeader from "./header/AppHeader";
import Head from "./head/Head";
import Footer from "./footer/Footer";
import { getCookie } from "../utils/Utils";
import Cookies from "js-cookie";
import { getActiveEnvironmentVariable } from "../utils/Utils";

import Construction_Footer from "../construction_website/components/Construction_Footer";
import Construction_Navbar from "../construction_website/components/Construction_Navbar";
import NavbarPhoto from "../Photography/HomePage/NavbarPhoto";
import PhotoFooter from "../Photography/HomePage/PhotoFooter";

export const createcontext = createContext();

const Layout = ({ title, app }) => {
  const [heroSection, setHeroSection] = useState(false);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [urlCheck, setUrlCheck] = useState(false);
  const activeVariable = getActiveEnvironmentVariable();

  useEffect(() => {
    const heroCookie = getCookie("Herosection");
    setHeroSection(heroCookie === "true");
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLat(position.coords.latitude);
        setLong(position.coords.longitude);
      });
    } else {
      Cookies.remove("latLongCity", {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
      console.log("Geolocation is not supported");
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  const getCityName = async (latitude, longitude) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=en`
      );
      const data = await response.json();

      if (data.address && data.address.city) {
        const now = new Date();
        const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
        Cookies.set("latLongCity", data.address.city, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: expires,
        });
        console.log("latLongCity added with city: ", data.address.city);
      } else {
        Cookies.remove("latLongCity", {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        console.log("latLongCity removed because city not found");
      }
    } catch (error) {
      console.error("Error fetching city name:", error);
      Cookies.remove("latLongCity", {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
      });
    }
  };

  useEffect(() => {
    if (lat && long) {
      getCityName(lat, long);
    }
  }, [lat, long]);

  useEffect(() => {
    const currentURL = window.location.pathname;
    if (currentURL) {
      const regex = /^\/profiles\/specific\/\d+$/;

      if (regex.test(currentURL)) {
        setUrlCheck(true);
      } else {
        setUrlCheck(false);
      }
    } else {
      setUrlCheck(false);
    }
  }, [window.location.pathname]);

  return (
    <>
      <Head title={title || "Loading..."} />
      <AppRoot>
        <createcontext.Provider value={{ heroSection, setHeroSection }}>
          <AppMain>
            {activeVariable === "construction" ? (
              <>
                {/* <Construction_Navbar /> */}
                <Construction_Navbar className={urlCheck ? "" : "sticky-top"} />
                <Outlet />
                <Construction_Footer />
              </>
            ) : activeVariable === "photography" ? (
              <>
                <NavbarPhoto className={urlCheck ? "" : "sticky-top"} />
                <Outlet />
                <PhotoFooter />
              </>
            ) : activeVariable === "CRM" ? (
              <>
                <AppHeader app={app} className={urlCheck ? "" : "sticky-top"} />
                <Outlet />
                <Footer />
              </>
            ) : (
              <>
                <AppHeader app={app} />
                <Outlet />
                <Footer />
              </>
            )}
          </AppMain>
        </createcontext.Provider>
      </AppRoot>
    </>
  );
};

export default Layout;
